import React from "react";

const AutomatedNotificationLogs = () => {
  return (
    <div className="p-8">
      {/* Notification Log Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Automated Notification Logs</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Sent Notifications Overview */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Notification Log Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Sent Notifications Overview:</h3>
            <p>Lists all notifications sent, with details like date, content, audience, and delivery channel.</p>
          </div>
          {/* Delivery Success Metrics */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Delivery Success Metrics:</h3>
            <p>Displays statistics on the success rate of notifications, including delivery failures and reasons for failure.</p>
          </div>
          {/* User Engagement Analytics */}
          <div>
            <h3 className="text-lg font-semibold mb-2">User Engagement Analytics:</h3>
            <p>Tracks how recipients interact with the notifications, measuring open rates, click-through rates, and subsequent actions.</p>
          </div>
        </div>
      </div>

      {/* Enhancements and Suggestions */}
      <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* Dynamic Notification Content Creation */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Dynamic Notification Content Creation:</h3>
          <p>Implement AI-driven tools to dynamically generate notification content based on user behaviour and preferences, enhancing personalization and engagement.</p>
        </div>
        {/* Real-Time Notification Performance Monitoring */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Real-Time Notification Performance Monitoring:</h3>
          <p>Develop a real-time dashboard to monitor the performance of ongoing notification campaigns, allowing quick adjustments to optimize delivery and response.</p>
        </div>
        {/* Advanced Filtering and Search in Logs */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Advanced Filtering and Search in Logs:</h3>
          <p>Enhance the log subpage with advanced filtering and search capabilities, enabling administrators to quickly find specific notifications or trends in notification performance.</p>
        </div>
        {/* Notification A/B Testing Tool */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Notification A/B Testing Tool:</h3>
          <p>Introduce a feature to conduct A/B testing on different notification formats, contents, and timings to identify the most effective strategies.</p>
        </div>
        {/* Comprehensive Notification Impact Analysis */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Comprehensive Notification Impact Analysis:</h3>
          <p>Offer in-depth analytics on the impact of notifications on user behaviour, retention, and conversion, helping to quantify the ROI of notification campaigns.</p>
        </div>
      </div>
    </div>
  );
};

export default AutomatedNotificationLogs;
