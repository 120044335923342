import React from "react";

const RolesAndPermissions = () => {
  return (
    <div className="p-8">
      <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">
        Roles and Permissions
      </h2>
      {/* Roles Management Subpage */}
      <div className="mb-8">
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          <div className="mb-4">
            <h2 className="text-2xl ont-teko font-semibold mb-4">
              Roles Management Subpage
            </h2>
            <h3 className="text-lg ont-teko font-semibold mb-2">
              Role List and Details:
            </h3>
            <p className="ont-teko">
              Displays existing roles within the platform with an option to view
              detailed descriptions and associated permissions.
            </p>
          </div>
          <div>
            <h3 className="text-lg ont-teko font-semibold mb-2">
              Add/Edit Role Form:
            </h3>
            <p className="ont-teko">
              Provides the ability to create new roles or edit existing ones,
              including the specific permissions attached to each role.
            </p>
          </div>
        </div>
      </div>

      {/* Permissions Management Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <div className="mb-4">
          <h2 className="text-2xl ont-teko font-semibold mb-4">
            Permissions Management Subpage
          </h2>
          <h3 className="text-lg ont-teko font-semibold mb-2">
            Permissions Overview:
          </h3>
          <p className="ont-teko">
            Lists all available permissions with descriptions, showing which
            roles they are associated with.
          </p>
        </div>
        <div>
          <h3 className="text-lg ont-teko font-semibold mb-2">
            Custom Role Creation Tool:
          </h3>
          <p className="ont-teko">
            Allows for the creation of custom roles with a selectable set of
            permissions, enabling tailored access control depending on the needs
            of the organization.
          </p>
        </div>
      </div>
      <div className="mt-6 mb-8">
        <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">
          Enhancements and Suggestions
        </h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Audit Trail for Managers */}
          <h2 className="text-2xl ont-teko font-semibold mb-4">
            Audit Trail for Managers:
          </h2>
          <div className="mb-4">
            <p className="ont-teko">
              Implement a feature within the Manager Details to track changes
              made to manager profiles and roles, providing a clear audit trail.
            </p>
          </div>
          {/* Role Templates */}
          <h2 className="text-2xl ont-teko font-semibold mb-4">
            Role Templates:
          </h2>
          <div className="mb-4">
            <p className="ont-teko">
              Offer pre-defined role templates in the Roles Management area to
              simplify the creation of common roles within the organization.
            </p>
          </div>
          {/* Permissions Matrix */}
          <h2 className="text-2xl ont-teko font-semibold mb-4">
            Permissions Matrix:
          </h2>
          <div className="mb-4">
            <p className="ont-teko">
              In the Permissions Management subpage, introduce a matrix view to
              easily see and manage the permissions assigned to different roles,
              enhancing the clarity and ease of permissions assignment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesAndPermissions;
