import React from "react";

const FeatureToggles = () => {
  return (
    <div className="p-8">
      {/* Core Features Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Feature Toggles and Functionality Management</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Module Activation/Deactivation */}
          <div className="mb-4">
            <h3 className="text-2xl font-semibold mb-4">Core Features Subpage</h3>
            <h4 className="text-lg font-semibold mb-2">Module Activation/Deactivation:</h4>
            <p>Switches to activate or deactivate specific features or modules of the platform, such as live chat, blog, or marketplace.</p>
          </div>
          {/* Performance Settings */}
          <div>
            <h4 className="text-lg font-semibold mb-2">Performance Settings:</h4>
            <p>Adjustments for optimizing the performance of the platform, including caching and load balancing configurations.</p>
          </div>
        </div>
      </div>

      {/* Advanced Settings Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Advanced Settings Subpage</h2>
        {/* Custom Feature Development Tools */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Custom Feature Development Tools:</h3>
          <p>Interface for creating or integrating custom features specific to the platform’s needs.</p>
        </div>
        {/* API and Integration Settings */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">API and Integration Settings:</h3>
          <p>Management of API keys and settings for third-party service integrations.</p>
        </div>
        {/* Security and Access Controls */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Security and Access Controls:</h3>
          <p>Configuration options for platform security settings, including user access levels, authentication methods, and data encryption.</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureToggles;
