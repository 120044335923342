import React, { useState, useEffect } from 'react';

const SecurityAndComplianceDashboard = () => {
  // State for security alerts
  const [securityAlerts, setSecurityAlerts] = useState([]);

  // State for compliance status with specific regulations
  const [complianceStatus, setComplianceStatus] = useState({
    gdpr: 'Pending',
    hipaa: 'Pending',
    soc2: 'Pending',
  });

  // Simulate updating security alerts and compliance status
  useEffect(() => {
    // Simulated security alerts data
    const alertsData = [
      { id: 1, type: 'Critical', message: 'Unauthorized access detected' },
      { id: 2, type: 'High', message: 'Potential data breach detected' },
      { id: 3, type: 'Medium', message: 'SSL certificate expired' },
    ];
    setSecurityAlerts(alertsData);

    // Simulate changing compliance status randomly for each regulation
    const intervalId = setInterval(() => {
      const statuses = ['Compliant', 'Non-Compliant', 'Pending'];
      setComplianceStatus({
        gdpr: statuses[Math.floor(Math.random() * statuses.length)],
        hipaa: statuses[Math.floor(Math.random() * statuses.length)],
        soc2: statuses[Math.floor(Math.random() * statuses.length)],
      });
    }, 10000); // Update compliance status every 10 seconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Helper function to determine text color based on compliance status
  const getStatusTextColor = (status) => {
    if (status === 'Compliant') {
      return 'text-green-600'; // Green text for compliant status
    } else if (status === 'Non-Compliant') {
      return 'text-red-600'; // Red text for non-compliant status
    } else {
      return 'text-yellow-600'; // Yellow text for pending status
    }
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-8">Security and Compliance</h1>

      {/* Security Alerts Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Security Alerts</h2>
        {securityAlerts.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {securityAlerts.map((alert) => (
              <li key={alert.id} className="py-2">
                <div>
                  <p className="text-xl Teko sans-serif font-semibold">{alert.type} Alert:</p>
                  <p className="text-sm text-gray-600">{alert.message}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600 italic">No security alerts</p>
        )}
      </div>

      {/* Compliance Status Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Compliance Status</h2>
        <div className="mb-4">
          <p className="text-xl Teko sans-serif font-semibold">GDPR Compliance:</p>
          <p className={getStatusTextColor(complianceStatus.gdpr)}>{complianceStatus.gdpr}</p>
        </div>
        <div className="mb-4">
          <p className="text-xl Teko sans-serif font-semibold">HIPAA Compliance:</p>
          <p className={getStatusTextColor(complianceStatus.hipaa)}>{complianceStatus.hipaa}</p>
        </div>
        <div>
          <p className="text-xl Teko sans-serif font-semibold">SOC 2 Compliance:</p>
          <p className={getStatusTextColor(complianceStatus.soc2)}>{complianceStatus.soc2}</p>
        </div>
      </div>
    </div>
  );
};

export default SecurityAndComplianceDashboard;
