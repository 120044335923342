import React from "react";

const Paragraph = ({ variant, children, className }) => {
  return (
    <>
      {variant === "Main" && (
        <p
          className={`
          ${className} font-ibmplex font-normal normal-case text-[13px] leading-[16px] tracking-[0.70px] md:text-[12px] md:leading-[17px] md:tracking-[0.50px] lg:text-[13px] lg:leading-[18px] lg:tracking-[0.50px] xl:text-[13px] xl:leading-[18px] xl:tracking-[0.50px]`}
        >
          {children}
        </p>
      )}
      {variant === "Smaller" && (
        <p
          className={`
          ${className} font-ibmplex font-normal normal-case text-[11px] leading-[12px] tracking-[0.70px] md:text-[10px] md:leading-[13px] md:tracking-[0.60px] lg:text-[11px] lg:leading-[14px] lg:tracking-[0.50px] xl:text-[12px] xl:leading-[17px] xl:tracking-[0.50px]`}
        >
          {children}
        </p>
      )}
      {variant === "ExtraSmaller" && (
        <p
          className={`
          ${className} font-ibmplex font-normal normal-case text-[9px] leading-normal tracking-[0.70px] md:text-[9px] md:leading-[13px] md:tracking-[0.60px] lg:text-[10px] lg:leading-[14px] lg:tracking-[0.50px] xl:text-[11px] xl:leading-[17px] xl:tracking-[0.50px]`}
        >
          {children}
        </p>
      )}
    </>
  );
};
export default Paragraph;
