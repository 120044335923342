import React from "react";

const TicketingSystem = () => {
  return (
    <div className="p-8">
      {/* Ticket Management Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Ticketing System</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Ticket Management Subpage</h2>
          {/* Ticket Overview Dashboard */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Ticket Overview Dashboard:</h3>
            <p>A comprehensive view of all support tickets, sortable by status, priority, and assignment.</p>
          </div>
          {/* Ticket Assignment Tools */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Ticket Assignment Tools:</h3>
            <p>Features for assigning tickets to specific team members or departments based on expertise and current workload.</p>
          </div>
          {/* Resolution Tracking */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Resolution Tracking:</h3>
            <p>Mechanism to track the progress of ticket resolution, including stages, times, and outcomes.</p>
          </div>
        </div>
      </div>

      {/* Performance Analytics Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Performance Analytics Subpage</h2>
        {/* Support Metrics Dashboard */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Support Metrics Dashboard:</h3>
          <p>Analytics on the overall performance of the support system, including average resolution time, ticket backlog, and customer satisfaction ratings.</p>
        </div>
        {/* Individual Performance Tracking */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Individual Performance Tracking:</h3>
          <p>Detailed analytics on the performance of individual support agents, identifying strengths and areas for improvement.</p>
        </div>
      </div>

      {/* Enhancements and Suggestions */}
      <div className="mt-6">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* AI-Powered Chatbot Integration */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">AI-Powered Chatbot Integration:</h3>
            <p>Implement an AI-powered chatbot within the Live Chat System to provide immediate responses to common inquiries, reducing the workload on human operators.</p>
          </div>
          {/* Advanced Ticket Prioritization Algorithm */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Advanced Ticket Prioritization Algorithm:</h3>
            <p>Develop a more sophisticated algorithm for ticket prioritization, automatically categorizing and assigning urgency based on keyword analysis, customer history, and issue severity.</p>
          </div>
          {/* Self-Service Knowledge Base */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Self-Service Knowledge Base:</h3>
            <p>Create an integrated knowledge base that allows customers to find solutions independently, linked to the live chat and ticketing systems for easy reference during support interactions.</p>
          </div>
          {/* Customer Feedback Collection Tool */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Customer Feedback Collection Tool:</h3>
            <p>Integrate a mechanism for collecting and analysing customer feedback post-resolution, directly within the Ticketing System, to continuously improve support services.</p>
          </div>
          {/* Cross-Platform Support Integration */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Cross-Platform Support Integration:</h3>
            <p>Ensure the Support system integrates seamlessly with other communication platforms (e.g., social media, email) to provide a unified customer support experience across all channels.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketingSystem;
