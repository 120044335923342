import React, { useState } from 'react';

const UserProfile = ({ userData }) => {
  // Generate random user details for demonstration
  const generateRandomUser = () => {
    const randomName = generateRandomName();
    const randomGender = generateRandomGender();
    const randomLocation = generateRandomLocation();
    const randomAccountCreationDate = generateRandomDate();

    return {
      name: randomName,
      gender: randomGender,
      location: randomLocation,
      accountCreationDate: randomAccountCreationDate,
      age: generateRandomAge(), // Generate random age (between 18 and 60)
      activityHistory: generateRandomActivityHistory(),
      postInteractionMetrics: {
        likes: generateRandomNumber(100), // Random likes (0-100)
        comments: generateRandomNumber(50), // Random comments (0-50)
        shares: generateRandomNumber(20) // Random shares (0-20)
      },
      overallEngagementScore: generateRandomNumber(100) // Random engagement score (0-100)
    };
  };

  const [activeSubpage, setActiveSubpage] = useState('userDetails');
  const [randomUser] = useState(generateRandomUser());

  const handleSubpageChange = (subpage) => {
    setActiveSubpage(subpage);
  };

  return (
    <div className="bg-pastalblue profile-overview bg-gray-100 p-4 mt-6 rounded-md shadow-md">
      <h1 className="text-2xl font-bold mb-4">Profile Overview</h1>
      <nav className="flex space-x-4 mb-4">
        <button
          className={`px-4 py-2 rounded-md text-gray-700 font-medium ${
            activeSubpage === 'userDetails' ? 'bg-brightblue text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSubpageChange('userDetails')}
        >
          User Details
        </button>
        <button
          className={`px-4 py-2 rounded-md text-gray-700 font-medium ${
            activeSubpage === 'engagementStats' ? 'bg-brightblue text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSubpageChange('engagementStats')}
        >
          Engagement Statistics
        </button>
      </nav>

      {activeSubpage === 'userDetails' && (
        <div className="user-details border border-gray-200 rounded-md p-4 bg-pastalblue">
          <h2 className="text-xl Teko sans-serif font-bold mb-2">User Details</h2>
          <ul className="list-disc space-y-2">
            <p>
              <b>Name:</b> {randomUser.name}
            </p>
            <p>
              <b>Age:</b> {randomUser.age}
            </p>
            <p>
              <b>Gender:</b> {randomUser.gender}
            </p>
            <p>
              <b>Location:</b> {randomUser.location}
            </p>
            <p>
              <b>Account Created:</b> {randomUser.accountCreationDate}
            </p>
          </ul>
          <h3 className="text-xl Teko sans-serif font-bold mt-4">Activity History</h3>
          <ul className="list-disc space-y-2">
            {randomUser.activityHistory.map((activity, index) => (
              <p key={index}>{activity}</p>
            ))}
          </ul>
        </div>
      )}

      {activeSubpage === 'engagementStats' && (
        <div className="engagement-stats border border-gray-200 rounded-md p-4 bg-pastalblue">
          <h2 className="text-xl Teko sans-serif font-bold mb-2">Engagement Statistics</h2>
          <div className="stat-section">
            <h3 className="text-md font-bold">Login Frequency</h3>
            {/* Placeholder for login frequency graph */}
            <p>Login frequency graph goes here</p>
          </div>
          <div className="stat-section mt-4">
            <h3 className="text-md font-bold">Post Interaction Metrics</h3>
            <ul className="list-disc space-y-2">
              <p>
                <b>Likes:</b> {randomUser.postInteractionMetrics.likes || 0}
              </p>
              <p>
                <b>Comments:</b> {randomUser.postInteractionMetrics.comments || 0}
              </p>
              <p>
                <b>Shares:</b> {randomUser.postInteractionMetrics.shares || 0}
              </p>
            </ul>
          </div>
          <div className="stat-section mt-4">
            <h3 className="text-md font-bold">Overall Engagement Score</h3>
            <p>{randomUser.overallEngagementScore}</p>
          </div>
        </div>
      )}
    </div>
  );
};

// Helper functions to generate random data

const generateRandomName = () => {
  const names = ['Alice', 'Bob', 'Charlie', 'David', 'Emma', 'Frank', 'Grace', 'Henry', 'Ivy', 'Jack'];
  return names[Math.floor(Math.random() * names.length)];
};

const generateRandomGender = () => {
  const genders = ['Male', 'Female', 'Non-Binary'];
  return genders[Math.floor(Math.random() * genders.length)];
};

const generateRandomLocation = () => {
  const locations = ['New York', 'Los Angeles', 'Chicago', 'San Francisco', 'Miami', 'Seattle', 'Dallas'];
  return locations[Math.floor(Math.random() * locations.length)];
};

const generateRandomDate = () => {
  const year = 2020 - Math.floor(Math.random() * 5); // Account created within the last 5 years
  const month = Math.floor(Math.random() * 12) + 1; // Month (1-12)
  const day = Math.floor(Math.random() * 28) + 1; // Day (1-28)
  return `${month}/${day}/${year}`;
};

const generateRandomAge = () => {
  return Math.floor(Math.random() * (60 - 18 + 1)) + 18; // Random age between 18 and 60
};

const generateRandomActivityHistory = () => {
  const activities = [
    'Post created',
    'Commented on a post',
    'Liked a post',
    'Logged in',
    'Shared a post',
    'Updated profile'
  ];
  const numActivities = Math.floor(Math.random() * 5) + 1; // Random number of activities (1-5)
  return Array.from({ length: numActivities }, () => activities[Math.floor(Math.random() * activities.length)]);
};

const generateRandomNumber = (max) => {
  return Math.floor(Math.random() * (max + 1)); // Random number (0-max)
};

export default UserProfile;
