// src/ContentManagementDashboard.js

import React, { useState, useEffect } from 'react';

const ContentManagementDashboard = () => {
  const [latestPosts, setLatestPosts] = useState([]);
  const [postEngagement, setPostEngagement] = useState({ views: 0, likes: 0, shares: 0 });

  // Simulated latest posts data
  useEffect(() => {
    // Simulated latest posts data (replace with real data fetching logic)
    const posts = [
      { id: 1, title: 'Introduction to React Hooks', views: 150, likes: 25, shares: 10 },
      { id: 2, title: 'Building Responsive UIs with Tailwind CSS', views: 220, likes: 30, shares: 15 },
      { id: 3, title: 'State Management with Redux', views: 180, likes: 20, shares: 8 },
      { id: 4, title: 'Introduction to React', views: 200, likes: 35, shares: 12 },
      { id: 5, title: 'Building Responsive UIs with Tailwind CSS', views: 200, likes: 35, shares: 12 },
      { id: 6, title: 'Introduction to React Hooks', views: 200, likes: 35, shares: 12 },

    ];
    setLatestPosts(posts);
  }, []);

  // Simulated post engagement data (updated every 10 seconds)
  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate update of post engagement data
      const newViews = postEngagement.views + Math.floor(Math.random() * 10);
      const newLikes = postEngagement.likes + Math.floor(Math.random() * 5);
      const newShares = postEngagement.shares + Math.floor(Math.random() * 3);
      setPostEngagement({ views: newViews, likes: newLikes, shares: newShares });
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [postEngagement]);

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-8">Content and Posts Management </h1>

      {/* Latest Posts Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Latest Posts</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {latestPosts.map((post) => (
            <div key={post.id} className="border p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-2">{post.title}</h3>
              <div className="flex justify-between items-center text-sm text-gray-600">
                <span>{post.views} Views</span>
                <span>{post.likes} Likes</span>
                <span>{post.shares} Shares</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Post Engagement Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Post Engagement</h2>
        <div className="grid grid-cols-3 gap-4">
          <div className="text-center">
            <h3 className="text-lg font-semibold">Views</h3>
            <p className="text-2xl font-bold text-blue-600">{postEngagement.views}</p>
          </div>
          <div className="text-center">
            <h3 className="text-lg font-semibold">Likes</h3>
            <p className="text-2xl font-bold text-green-600">{postEngagement.likes}</p>
          </div>
          <div className="text-center">
            <h3 className="text-lg font-semibold">Shares</h3>
            <p className="text-2xl font-bold text-yellow-600">{postEngagement.shares}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentManagementDashboard;
