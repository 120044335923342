import React, { useState, useEffect } from 'react';

const MarketingAndSEOWidgets = () => {
  // State for SEO performance metrics
  const [seoMetrics, setSEOMetrics] = useState({
    organicTraffic: 0,
    keywordRankings: [
      { keyword: 'React tutorial', position: 3 },
      { keyword: 'SEO tips', position: 5 },
      { keyword: 'Web development guide', position: 8 },
    ],
  });

  // State for marketing campaign performance
  const [campaigns, setCampaigns] = useState([
    { id: 1, name: 'Summer Sale', clicks: 250, conversions: 15 },
    { id: 2, name: 'Back-to-School Promotion', clicks: 180, conversions: 10 },
    { id: 3, name: 'Holiday Special', clicks: 320, conversions: 20 },
  ]);

  // Simulate updating SEO metrics and campaign performance
  useEffect(() => {
    const seoInterval = setInterval(() => {
      const updatedMetrics = {
        organicTraffic: Math.floor(Math.random() * 1000), // Random organic traffic
        keywordRankings: seoMetrics.keywordRankings.map((item) => ({
          ...item,
          position: Math.floor(Math.random() * 20) + 1, // Random keyword ranking position (1-20)
        })),
      };
      setSEOMetrics(updatedMetrics);
    }, 10000); // Update SEO metrics every 10 seconds

    const campaignInterval = setInterval(() => {
      const updatedCampaigns = campaigns.map((campaign) => ({
        ...campaign,
        clicks: campaign.clicks + Math.floor(Math.random() * 20), // Simulate click increase
        conversions: campaign.conversions + Math.floor(Math.random() * 3), // Simulate conversion increase
      }));
      setCampaigns(updatedCampaigns);
    }, 15000); // Update campaign performance every 15 seconds

    return () => {
      clearInterval(seoInterval);
      clearInterval(campaignInterval);
    };
  }, [seoMetrics, campaigns]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8">Marketing and SEO</h1>

      {/* SEO Performance Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">SEO Performance</h2>
        <div className="mb-4">
          <p className="text-lg Teko sans-serif font-semibold">Organic Traffic:</p>
          <p className="text-2xl font-bold">{seoMetrics.organicTraffic} visits</p>
        </div>
        <div>
          <p className="text-lg font-semibold">Keyword Rankings:</p>
          <ul className="divide-y divide-gray-200">
            {seoMetrics.keywordRankings.map((item, index) => (
              <li key={index} className="py-2">
                <span className="font-semibold">{item.keyword}: </span>
                <span className="text-gray-600">Position {item.position}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Marketing Campaign Performance Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Marketing Campaign Performance</h2>
        <ul className="divide-y divide-gray-200">
          {campaigns.map((campaign) => (
            <li key={campaign.id} className="py-2">
              <div>
                <p className="text-lg font-semibold">{campaign.name}</p>
                <p className="text-gray-600">Clicks: {campaign.clicks}</p>
                <p className="text-gray-600">Conversions: {campaign.conversions}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MarketingAndSEOWidgets;
