import React from 'react'
import Section1 from '../../sections/SecurityFraud/VerificationTools/Section1'
import Heading from '../../components/common/Typography/Heading'

const VerificationTools = () => {
  return (
    <>
    <div>
      <Heading variant={"h4"}>
      Security & Fraud     
      </Heading>
      <div>
        <Section1/>
      </div>
    </div>
  </>
  )
}

export default VerificationTools