import React, { useState, useEffect } from 'react';

const PerformanceDashboard = () => {
  // State for system health metrics
  const [cpuUsage, setCpuUsage] = useState(0);
  const [memoryUsage, setMemoryUsage] = useState(0);
  const [uptime, setUptime] = useState(0);

  // State for error alerts
  const [errors, setErrors] = useState([]);

  // Function to fetch system health data (simulated with random values)
  const fetchSystemData = () => {
    const mockData = {
      cpuUsage: Math.floor(Math.random() * 100), // Random CPU usage percentage
      memoryUsage: Math.floor(Math.random() * 100), // Random memory usage percentage
      uptime: Math.floor(Math.random() * (24 * 60 * 60)), // Random uptime in seconds (up to 24 hours)
    };

    // Update system health state
    setCpuUsage(mockData.cpuUsage);
    setMemoryUsage(mockData.memoryUsage);
    setUptime(mockData.uptime);
  };

  // Function to trigger an error alert
  const triggerErrorAlert = () => {
    const timestamp = new Date().toLocaleTimeString();
    const newError = `System error detected at ${timestamp}`;
    setErrors([...errors, newError]);
  };

  // Use effect to fetch system data and set interval to update every 5 seconds
  useEffect(() => {
    // Initial fetch
    fetchSystemData();

    // Set interval to fetch and update data every 5 seconds
    const intervalId = setInterval(fetchSystemData, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Format uptime in HH:MM:SS
  const formatUptime = (uptimeSeconds) => {
    const hours = Math.floor(uptimeSeconds / 3600);
    const minutes = Math.floor((uptimeSeconds % 3600) / 60);
    const seconds = uptimeSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl eko sans font-bold mb-8">System and Performance </h1>

      {/* System Health Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">System Health</h2>
        <div className="mb-4">
          <p className="text-gray-700">CPU Usage:</p>
          <p className="text-xl Teko sans-serif font-semibold text-blue-600">{cpuUsage}%</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-700">Memory Usage:</p>
          <p className="text-xl Teko sans-serif font-semibold text-green-600">{memoryUsage}%</p>
        </div>
        <div>
          <p className="text-gray-700">Uptime:</p>
          <p className="text-xl Teko sans-serif font-semibold">{formatUptime(uptime)}</p>
        </div>
        <button
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
          onClick={triggerErrorAlert}
        >
          Trigger Error Alert
        </button>
      </div>

      {/* Error and Alert Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-xl font-bold mb-4">Error and Alert</h2>
        <div>
          <h3 className="text-xl Teko sans-serif font-semibold mb-2">Error Log</h3>
          {errors.length > 0 ? (
            <ul className="list-disc list-inside">
              {errors.map((error, index) => (
                <li key={index} className="text-sm text-red-600">
                  {error}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600 italic">No errors detected</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;
