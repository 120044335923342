
import React, { useState, useEffect } from 'react';

const Users = () => {
    const [activeUsers, setActiveUsers] = useState(0);
    const [activities, setActivities] = useState([]);
    const [newRegistrations, setNewRegistrations] = useState(0);
  
    // Simulate updating active users count (replace with real-time logic)
    useEffect(() => {
      const interval = setInterval(() => {
        const randomUsers = Math.floor(Math.random() * 100); // Random number of users
        setActiveUsers(randomUsers);
      }, 5000); // Update every 5 seconds
  
      return () => clearInterval(interval);
    }, []);
  
    // Function to add new activity to the stream
    const addActivity = (activity) => {
      setActivities((prevActivities) => [activity, ...prevActivities.slice(0, 9)]); // Limit to 10 activities
    };
  
    // Function to handle new registrations (example)
    const handleNewRegistration = () => {
      setNewRegistrations((prevCount) => prevCount + 1);
    };
  
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl Teko sans-serif font-bold mb-8">User and Engagement</h1>
  
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Active Users Widget */}
          <div className="p-4 bg-pastalblue rounded-lg shadow-md">
            <h2 className="text-xl Teko sans-serif mb-2">Active Users</h2>
            <p className="text-4xl Teko sans-serif">{activeUsers}</p>
          </div>
  
          {/* User Activity Stream Widget */}
          <div className="p-4 bg-pastalblue rounded-lg shadow-md">
            <h2 className="text-xl Teko sans-serif mb-2 ">User Activity Stream</h2>
            <ul className="divide-y divide-gray-300">
              {activities.map((activity, index) => (
                <li key={index} className="py-2">{activity}</li>
              ))}
            </ul>
          </div>
  
          {/* New Registrations Widget */}
          <div className="p-4 bg-pastalblue rounded-lg shadow-md">
            <h2 className="text-xl Teko sans-serif mb-2">New Registrations</h2>
            <p className="text-4xl Teko sans-serif">{newRegistrations}</p>
          </div>
        </div>
      </div>
    );
  };
  
  export default Users;
  