import React, { useState, useEffect } from 'react';

const LogAndActivityDashboard = () => {
  const [systemLogs, setSystemLogs] = useState([]);
  const [userLogs, setUserLogs] = useState([]);

  useEffect(() => {
    const fetchSystemLogs = () => {
      // Simulated system logs data
      const logsData = [
        { id: 1, timestamp: '2022-04-10 10:30:15', message: 'System started' },
        { id: 2, timestamp: '2022-04-10 11:15:20', message: 'Database backup completed' },
        { id: 3, timestamp: '2022-04-10 12:00:45', message: 'Configuration updated' },
      ];
      setSystemLogs(logsData);
    };

    const fetchUserLogs = () => {
      // Simulated user activity logs data
      const logsData = [
        { id: 1, userId: '123', action: 'Login', timestamp: '2022-04-10 10:30:15' },
        { id: 2, userId: '456', action: 'Post created', timestamp: '2022-04-10 11:15:20' },
        { id: 3, userId: '789', action: 'Profile updated', timestamp: '2022-04-10 12:00:45' },
      ];
      setUserLogs(logsData);
    };

    // Fetch initial logs
    fetchSystemLogs();
    fetchUserLogs();

    // Set interval to fetch and update logs every 30 seconds
    const intervalId = setInterval(() => {
      fetchSystemLogs();
      fetchUserLogs();
    }, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-8">Log and Activity</h1>

      {/* System Log Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">System Logs</h2>
        {systemLogs.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {systemLogs.map((log) => (
              <li key={log.id} className="py-4">
                <p className="text-sm text-gray-600 mb-2">{log.timestamp}</p>
                <p className="text-base">{log.message}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600 italic">No system logs available</p>
        )}
      </div>

      {/* User Activity Log Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">User Activity Logs</h2>
        {userLogs.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {userLogs.map((log) => (
              <li key={log.id} className="py-4">
                <p className="text-sm text-gray-600 mb-2">{log.timestamp}</p>
                <p className="text-base">
                  User {log.userId}: {log.action}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600 italic">No user activity logs available</p>
        )}
      </div>
    </div>
  );
};

export default LogAndActivityDashboard;
