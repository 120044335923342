import React from 'react'
import Section1 from '../../sections/SEOTools/KeywordTracking/Section1'
import Heading from '../../components/common/Typography/Heading'

const KeywordTracking = () => {
  return (
    <>
    <div>
      <Heading variant={"h4"}>
      SEO Tools
      </Heading>
      <div>
        <Section1/>
      </div>
    </div>
  </>
  )
}

export default KeywordTracking