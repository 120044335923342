import React from "react";

const SubscriptionManagement = () => {
  return (
    <div className="p-8">
      {/* Subscription Setup Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Subscription Management</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Tier Configuration */}
          <div className="mb-4">
            <h2 className="text-2xl ont-teko font-semibold mb-4">Subscription Setup Subpage</h2>
            <h3 className="text-lg ont-teko font-semibold mb-2">Tier Configuration:</h3>
            <p className="ont-teko">Interface for defining and editing subscription tiers, including setting benefits and pricing for each level.</p>
          </div>
          {/* Promotions and Discounts Manager */}
          <div>
            <h3 className="text-lg ont-teko font-semibold mb-2">Promotions and Discounts Manager:</h3>
            <p className="ont-teko">Tool for creating and managing promotional offers or discounts on subscriptions.</p>
          </div>
        </div>
      </div>

      {/* Subscriber Analytics Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* Subscriber Overview */}
        <div className="mb-4">
          <h2 className="text-2xl ont-teko font-semibold mb-4">Subscriber Analytics Subpage</h2>
          <h3 className="text-lg ont-teko font-semibold mb-2">Subscriber Overview:</h3>
          <p className="ont-teko">Displays metrics and trends related to subscription numbers, churn rates, and growth patterns.</p>
        </div>
        {/* Revenue Impact Analysis */}
        <div>
          <h3 className="text-lg ont-teko font-semibold mb-2">Revenue Impact Analysis:</h3>
          <p className="ont-teko">Analyses the financial impact of subscription changes, including the effect of promotions and discounts.</p>
        </div>
      </div>

      {/* Enhancements and Suggestions */}
      <div className="mb-8 mt-6">
        <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Automated Financial Health Monitoring */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Automated Financial Health Monitoring:</h3>
            <p className="ont-teko">Implement AI-driven tools to continuously monitor financial health, alerting administrators to potential issues or opportunities based on real-time data analysis.</p>
          </div>
          {/* Advanced Subscription Metrics */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Advanced Subscription Metrics:</h3>
            <p className="ont-teko">Integrate advanced analytics for subscription management, tracking user lifetime value (LTV), acquisition costs, and profitability by tier.</p>
          </div>
          {/* Dynamic Pricing Models */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Dynamic Pricing Models:</h3>
            <p className="ont-teko">Develop capabilities for dynamic pricing, allowing the platform to automatically adjust subscription pricing based on market demand, user engagement, and other relevant factors.</p>
          </div>
          {/* Financial Forecasting Tools */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Financial Forecasting Tools:</h3>
            <p className="ont-teko">Incorporate forecasting tools in the Financial Analytics section to predict future financial scenarios based on current trends and historical data.</p>
          </div>
          {/* Integration with Accounting Software */}
          <div>
            <h3 className="text-lg ont-teko font-semibold mb-2">Integration with Accounting Software:</h3>
            <p className="ont-teko">Enable seamless integration with external accounting and financial management software to synchronize financial data and streamline accounting processes.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManagement;
