import React, { useState } from 'react';

const AdvancedSearchSubpage = () => {
  const [searchCriteria, setSearchCriteria] = useState({
    username: '',
    ageMin: '',
    ageMax: '',
    location: '',
    activityType: '',
    engagementLevel: '',
    customFilters: [],
    searchResults: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevCriteria) => ({
      ...prevCriteria,
      [name]: value,
    }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Perform search based on search criteria (not implemented here)
    console.log('Performing search:', searchCriteria);
    // Simulate search results (replace with actual search logic)
    const mockSearchResults = [
      { username: 'JohnDoe', age: 30, location: 'New York', activity: 'Post Creation', engagement: 'High' },
      { username: 'AliceSmith', age: 25, location: 'Los Angeles', activity: 'Comment', engagement: 'Medium' },
    ];
    setSearchCriteria((prevCriteria) => ({
      ...prevCriteria,
      searchResults: mockSearchResults,
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100 xl:ml-[-163px] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-pastalblue rounded-lg shadow-lg p-8">
        <h2 className="text-3xl font-bold text-primaryblue mb-6">Advanced Search Subpage</h2>

        {/* Search Form */}
        <form className="space-y-4" onSubmit={handleSearchSubmit}>
          {/* Username */}
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">Username</label>
            <input
              type="text"
              name="username"
              value={searchCriteria.username}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              placeholder="Enter username"
            />
          </div>

          {/* Age Range */}
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-semibold text-gray-700 mb-1">Minimum Age</label>
              <input
                type="number"
                name="ageMin"
                value={searchCriteria.ageMin}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Min"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-semibold text-gray-700 mb-1">Maximum Age</label>
              <input
                type="number"
                name="ageMax"
                value={searchCriteria.ageMax}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Max"
              />
            </div>
          </div>

          {/* Location */}
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">Location</label>
            <input
              type="text"
              name="location"
              value={searchCriteria.location}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              placeholder="Enter location"
            />
          </div>

          {/* Activity Type */}
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">Activity Type</label>
            <input
              type="text"
              name="activityType"
              value={searchCriteria.activityType}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              placeholder="Enter activity type"
            />
          </div>

          {/* Engagement Level */}
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">Engagement Level</label>
            <input
              type="text"
              name="engagementLevel"
              value={searchCriteria.engagementLevel}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              placeholder="Enter engagement level"
            />
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full bg-primaryblue text-white py-2 rounded-md hover:bg-primaryblue-dark"
            >
              Search
            </button>
          </div>
        </form>

        {/* Search Results Viewer */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold text-primaryblue mb-4">Search Results</h2>
          <div className="space-y-4">
            {searchCriteria.searchResults.length > 0 ? (
              searchCriteria.searchResults.map((result, index) => (
                <div key={index} className="bg-gray-200 p-4 rounded-lg">
                  <p className="text-lg font-semibold">{result.username}</p>
                  <p className="text-gray-700">Age: {result.age}</p>
                  <p className="text-gray-700">Location: {result.location}</p>
                  <p className="text-gray-700">Activity: {result.activity}</p>
                  <p className="text-gray-700">Engagement: {result.engagement}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-700">No results found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearchSubpage;



// import React from "react";

// const SearchAndFilters = () => {
//   return (
//     <div className="p-8">
//       {/* User Search & Filters */}
//       <div className="mb-8">
//       <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">User Search & Filters</h2>
//       <div className="bg-pastalblue shadow-md rounded-lg p-6">
//     <h2 className="text-2xl ont-teko font-semibold mb-4">Advanced Search Subpage</h2>
//     <div className="mb-4">
//       <h3 className="text-lg ont-teko font-semibold mb-2">Search by Demographics:</h3>
//       <p className="ont-teko">Allows searching based on user demographic details.</p>
//     </div>
//     <div className="mb-4">
//       <h3 className="text-lg ont-teko font-semibold mb-2">Search by Activity:</h3>
//       <p className="ont-teko">Enables searching for users based on their activities and engagement levels.</p>
//     </div>
//     <div className="mb-4">
//       <h3 className="text-lg ont-teko font-semibold mb-2">Custom Filter Creation:</h3>
//       <p className="ont-teko">Ability to create and save custom filters for future searches.</p>
//     </div>
//     <div>
//       <h3 className="text-lg ont-teko font-semibold mb-2">Search Results Viewer:</h3>
//       <p className="ont-teko">Displays the list of users matching the search criteria, with options to view detailed profiles or take administrative actions.</p>
//     </div>
//   </div>
//       </div>
//       {/* Enhancements and Suggestions */}
//       {/* <div className="mb-8">
//   <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
//   <div className="bg-pastalblue shadow-md rounded-lg p-6">
//     <h2 className="text-2xl ont-teko font-semibold mb-4">User Behaviour Analytics:</h2>
//     <div className="mb-4">
//       <p className="ont-teko">Incorporate advanced analytics tools in the Engagement Statistics subpage to provide deeper insights into user behaviour patterns, such as heatmaps, session replay, and user journey tracking. This can help in understanding how users interact with the platform and identify areas for improvement.</p>
//     </div>
//     <h2 className="text-2xl ont-teko font-semibold mb-4">Automated User Segmentation:</h2>
//     <div className="mb-4">
//       <p className="ont-teko">Implement machine learning algorithms to automatically segment users based on their activity, engagement level, and demographics. This can facilitate targeted marketing campaigns, personalized content, and improved user engagement strategies.</p>
//     </div>
//     <h2 className="text-2xl ont-teko font-semibold mb-4">User Risk Profiling:</h2>
//     <div className="mb-4">
//       <p className="ont-teko">In the User Activity Logs subpage, integrate a risk assessment tool that analyses user actions and flags potentially risky behaviour, such as frequent password resets, unusual login times, or patterns that deviate from the norm. This can help in early detection of security threats or fraudulent activities.</p>
//     </div>
//     <h2 className="text-2xl ont-teko font-semibold mb-4">Enhanced Search Capabilities:</h2>
//     <div className="mb-4">
//       <p className="ont-teko">For the User Search & Filters subpage, introduce advanced search capabilities such as natural language processing (NLP) to allow for more intuitive search queries. This can improve the ease of finding user information and enhance the overall administrative experience.</p>
//     </div>
//     <h2 className="text-2xl ont-teko font-semibold mb-4">Bulk User Management Actions:</h2>
//     <div className="mb-4">
//       <p className="ont-teko">Provide the ability to perform bulk actions such as sending communications, adjusting user statuses, or applying role changes directly from the search results interface. This can save time and improve operational efficiency for administrators.</p>
//     </div>
//     <h2 className="text-2xl ont-teko font-semibold mb-4">Customizable Dashboard Widgets:</h2>
//     <div>
//       <p className="ont-teko">Allow for customization of the User Management dashboard widgets, so administrators can prioritize and display information most relevant to their daily tasks and responsibilities.</p>
//     </div>
//   </div>
// </div> */}

//     </div>  
    
//   );
// };

// export default SearchAndFilters;
