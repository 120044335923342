import React from "react";

const SecondaryHeading = ({ variant, children, className }) => {
  return (
    <>
      {variant === "ExtraLargeParagrapHeading" && (
        <p
          className={`
          ${className} font-ptsans font-bold uppercase text-[14px] leading-[14px] tracking-[1px] md:text-[13px] md:leading-[13px] md:tracking-[1px] lg:text-[14px] lg:leading-[14px] lg:tracking-[1px] xl:text-[17px] xl:leading-[17px] xl:tracking-[1px] `}
        >
          {children}
        </p>
      )}
      {variant === "Paragrapheading" && (
        <p
          className={`
          ${className} font-ptsans font-bold uppercase text-[12px] leading-normal tracking-[1px] md:text-[11px] md:leading-[11px] md:tracking-[1px] lg:text-[12px] lg:leading-[12px] lg:tracking-[1px] xl:text-[13px] xl:leading-[13px] xl:tracking-[1px]`}
        >
          {children}
        </p>
      )}
      {variant === "Subheadingsmall" && (
        <p
          className={`
          ${className} font-ptsans font-semibold uppercase text-[10px] leading-normal tracking-[1px] md:text-[10px] md:leading-[10px] md:tracking-[1px] lg:text-[11px] lg:leading-[11px] lg:tracking-[1px] xl:text-[12px] xl:leading-[12px] xl:tracking-[1px]`}
        >
          {children}
        </p>
      )}
    </>
  );
};
export default SecondaryHeading;
