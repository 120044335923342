import React from "react";

const EmailCampaign = () => {
  return (
    <div className="p-8">
      {/* Campaign Creation Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Email Campaign</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Drag-and-Drop Editor */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Campaign Creation Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Drag-and-Drop Editor:</h3>
            <p>An intuitive tool for designing email layouts, adding content, images, links, and call-to-action buttons.</p>
          </div>
          {/* Template Library */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Template Library:</h3>
            <p>A selection of pre-designed email templates that can be customized for various campaigns.</p>
          </div>
          {/* Audience Segmentation Tool */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Audience Segmentation Tool:</h3>
            <p>Allows for the creation of targeted recipient lists based on user behavior, demographics, and previous interactions.</p>
          </div>
        </div>
      </div>

      {/* Performance Tracking Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Performance Tracking Subpage</h2>
        {/* Campaign Analytics Dashboard */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Campaign Analytics Dashboard:</h3>
          <p>Provides real-time data on email campaign performance, including open rates, click-through rates, conversion rates, and more.</p>
        </div>
        {/* A/B Testing Tool */}
        <div>
          <h3 className="text-lg font-semibold mb-2">A/B Testing Tool:</h3>
          <p>Enables testing different versions of emails to determine which elements perform best with the target audience.</p>
        </div>
      </div>
    </div>
  );
};

export default EmailCampaign;
