import React from "react";

const Heading = ({ variant, children, className }) => {
  return (
    <>
      {variant === "h1" && (
        <h1
          className={`
          ${className} font-teko font-bold uppercase text-[40px] leading-[35px] tracking-[0.50px] md:text-[40px] md:leading-[36px] md:tracking-[0.50px] lg:text-[47px] lg:leading-[41px] lg:tracking-[0.50px] xl:text-[55px] xl:leading-[49px] xl:tracking-[0.50px] text-offblack`}
        >
          {children}
        </h1>
      )}
      {variant === "h2" && (
        <h2
          className={`
          ${className} font-teko font-bold uppercase text-[35px] leading-[33px] tracking-[0.50px] md:text-[35px] md:leading-[34px] md:tracking-[0.50px] lg:text-[39px] lg:leading-[36px] lg:tracking-[0.50px] xl:text-[45px] xl:leading-[41px] xl:tracking-[0.50px]`}
        >
          {children}
        </h2>
      )}
      {variant === "h3" && (
        <h3
          className={`
         ${className} font-teko font-bold uppercase text-[30px] leading-[29px] tracking-[0.50px] md:text-[29px] md:leading-[30px] md:tracking-[0.50px] lg:text-[34px] lg:leading-[35px] lg:tracking-[0.50px] xl:text-[40px] xl:leading-[37px] xl:tracking-[0.50px text-offblack`}
        >
          {children}
        </h3>
      )}
      {variant === "h4" && (
        <h4
          className={`
         ${className} font-teko font-semibold capitalize text-[26px] leading-[27px] tracking-[0.50px] md:text-[26px] md:leading-[27px] md:tracking-[0.50px] lg:text-[30px] lg:leading-[31px] lg:tracking-[0.50px] xl:text-[35px] xl:leading-[36px] xl:tracking-[0.50px] text-offblack`}
        >
          {children}
        </h4>
      )}
      {variant === "h5" && (
        <h5
          className={`
         ${className} font-teko font-normal capitalize text-[19px] leading-[21px] tracking-[0.50px] md:text-[20px] md:leading-[21px] md:tracking-[0.50px] lg:text-[23px] lg:leading-[24px] lg:tracking-[0.50px] xl:text-[25px] xl:leading-[26px] xl:tracking-[0.50px]`}
        >
          {children}
        </h5>
      )}
      {variant === "h6" && (
        <h6
          className={`
         ${className} font-teko font-semibold capitalize text-base tracking-[0.50px]`}
        >
          {children}
        </h6>
      )}
    </>
  );
};
export default Heading;
