import React from "react";

const ReviewManagement = () => {
  return (
    <div className="p-8">
      {/* Moderation Tools Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Review Management</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Review Overview */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Moderation Tools Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Review Overview:</h3>
            <p>Displays all user reviews with sorting and filtering options to manage them effectively.</p>
          </div>
          {/* Response Mechanism */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Response Mechanism:</h3>
            <p>Enables administrators to directly respond to reviews and engage with users.</p>
          </div>
          {/* Trend Analysis Tool */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Trend Analysis Tool:</h3>
            <p>Analyses review trends over time, identifying patterns and sentiments in user feedback.</p>
          </div>
        </div>
      </div>

      {/* Review Analytics Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* Sentiment Analysis */}
        <div className="mb-4">
          <h2 className="text-2xl font-semibold mb-4">Review Analytics Subpage</h2>
          <h3 className="text-lg font-semibold mb-2">Sentiment Analysis:</h3>
          <p>Utilizes natural language processing (NLP) to determine the sentiment of reviews (positive, negative, neutral).</p>
        </div>
        {/* Review Metrics Dashboard */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Review Metrics Dashboard:</h3>
          <p>Showcases key metrics like average rating, review volume, and response rates.</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewManagement;
