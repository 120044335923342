import React from 'react'
import Section1 from '../../sections/PostsManagement/CategoryManagement/Section1'
import Heading from '../../components/common/Typography/Heading'

const CategoryManagement = () => {
  return (
    <>
    <div>
      <Heading variant={"h4"}>
      Posts      
      </Heading>
      <div>
        <Section1/>
      </div>
    </div>
  </>
  )
}

export default CategoryManagement