import React from "react";

const ComplianceReporting = () => {
  return (
    <div className="p-8">
      {/* Automated Reports Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Compliance Reporting</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Compliance Dashboard */}
          <div className="mb-4">
            <h2 className="text-2xl ont-teko font-semibold mb-4">Automated Reports Subpage</h2>
            <h3 className="text-lg ont-teko font-semibold mb-2">Compliance Dashboard:</h3>
            <p className="ont-teko">Provides an overview of compliance status with real-time monitoring of key metrics.</p>
          </div>
          {/* Report Generation Tool */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Report Generation Tool:</h3>
            <p className="ont-teko">Enables the automated creation of compliance reports, with customizable parameters like date range, type of compliance, and specific compliance metrics.</p>
          </div>
        </div>
      </div>

      {/* Audit Trail Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* Manual Audit Tools */}
        <div className="mb-4">
          <h2 className="text-2xl ont-teko font-semibold mb-4">Audit Trail Subpage</h2>
          <h3 className="text-lg ont-teko font-semibold mb-2">Manual Audit Tools:</h3>
          <p className="ont-teko">Tools for conducting manual audits, including access to raw data logs and user actions.</p>
        </div>
        {/* Audit Log Viewer */}
        <div>
          <h3 className="text-lg ont-teko font-semibold mb-2">Audit Log Viewer:</h3>
          <p className="ont-teko">Detailed view of audit logs, sortable by date, user, action, and compliance relevance.</p>
        </div>
      </div>

      {/* Enhancements and Suggestions */}
      <div className="mb-8 mt-6">
        <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Real-Time Alerts for Verification Issues */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Real-Time Alerts for Verification Issues:</h3>
            <p className="ont-teko">Implement real-time notifications for administrators when verification attempts fail or suspicious activities are detected, allowing for immediate action.</p>
          </div>
          {/* Enhanced Data Visualization for Compliance */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Enhanced Data Visualization for Compliance:</h3>
            <p className="ont-teko">Integrate advanced data visualization tools in the Compliance Reporting section to make it easier to understand compliance trends, identify issues, and make informed decisions.</p>
          </div>
          {/* Risk Assessment Module */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Risk Assessment Module:</h3>
            <p className="ont-teko">Add a risk assessment tool in the Verification Tools section to evaluate the risk level of users based on their verification status and activity patterns, helping in proactive fraud prevention.</p>
          </div>
          {/* Compliance Trend Analysis */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Compliance Trend Analysis:</h3>
            <p className="ont-teko">Offer trend analysis features in the Compliance Reporting section to track changes in compliance status over time, highlighting areas that need attention or improvement.</p>
          </div>
          {/* Integration with External Compliance Databases */}
          <div>
            <h3 className="text-lg ont-teko font-semibold mb-2">Integration with External Compliance Databases:</h3>
            <p className="ont-teko">Allow for seamless integration with external compliance and regulatory databases to automatically update and cross-reference compliance requirements, ensuring the platform stays up-to-date with the latest standards.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceReporting;
