import React from "react";

const AlertsAndNotifications = () => {
  return (
    <div className="p-8">
      {/* Alert Configuration Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Alerts and Notifications</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Threshold Setting Tool */}
          <div className="mb-4">
            <h3 className="text-2xl font-semibold mb-4">Alert Configuration Subpage</h3>
            <h4 className="text-lg font-semibold mb-2">Threshold Setting Tool:</h4>
            <p>Allows administrators to set thresholds for performance metrics, triggering alerts when these thresholds are exceeded.</p>
          </div>
          {/* Notification Management */}
          <div>
            <h4 className="text-lg font-semibold mb-2">Notification Management:</h4>
            <p>Manages the delivery of system alerts, specifying the channels (email, SMS, dashboard notifications) and recipients for different types of alerts.</p>
          </div>
        </div>
      </div>

      {/* Incident Management Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Incident Management Subpage</h2>
        {/* Incident Log and History */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Incident Management Subpage</h3>
          <h4 className="text-lg font-semibold mb-2">Incident Log and History:</h4>
          <p>Records and displays a history of all system incidents and alerts, including the nature of the issue, resolution status, and response actions.</p>
        </div>
        {/* Incident Response Tools */}
        <div>
          <h4 className="text-lg font-semibold mb-2">Incident Response Tools:</h4>
          <p>Provides mechanisms for responding to and managing incidents, including escalation procedures, incident tracking, and post-mortem analysis.</p>
        </div>
      </div>

      {/* Enhancements and Suggestions */}
      <div className="mt-6">
        {/* Enhancements and Suggestions */}
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Predictive Analytics for System Health */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Predictive Analytics for System Health:</h3>
            <p>Implement machine learning algorithms to predict potential system issues before they occur, based on trends and patterns in the performance data.</p>
          </div>
          {/* Integrated Performance Benchmarking */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Integrated Performance Benchmarking:</h3>
            <p>Include tools for benchmarking system performance against industry standards or historical performance levels, helping to identify areas for improvement.</p>
          </div>
          {/* Customizable Dashboard Widgets */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Customizable Dashboard Widgets:</h3>
            <p>Allow administrators to customize the System Health Dashboard, selecting which metrics to display and configuring the layout to suit their monitoring preferences.</p>
          </div>
          {/* Automated Remediation Actions */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Automated Remediation Actions:</h3>
            <p>Develop automated response actions for common system issues, such as restarting services or scaling resources, to address problems before they impact users.</p>
          </div>
          {/* Comprehensive Reporting on System Health */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Comprehensive Reporting on System Health:</h3>
            <p>Provide detailed reporting tools that compile system health data into periodic reports, offering insights into performance trends, incident frequency, and resolution effectiveness.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsAndNotifications;
