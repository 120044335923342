import React from "react";

const BackupScheduling = () => {
  return (
    <div className="p-8">
      {/* Backup Configuration Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Backup Scheduling</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Automated Backup Scheduler */}
          <div className="mb-4">
            <h3 className="text-2xl font-semibold mb-4">Backup Configuration Subpage</h3>
            <h4 className="text-lg font-semibold mb-2">Automated Backup Scheduler:</h4>
            <p>Tool for setting up automatic data backup intervals (hourly, daily, weekly, etc.) and specifying which data or assets to include.</p>
          </div>
          {/* Backup Type Selection */}
          <div>
            <h4 className="text-lg font-semibold mb-2">Backup Type Selection:</h4>
            <p>Options to choose full, incremental, or differential backups, depending on the need for efficiency and storage management.</p>
          </div>
        </div>
      </div>

      {/* Backup Management Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Backup Management Subpage</h2>
        {/* Backup History and Logs */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Backup History and Logs:</h3>
          <p>Detailed logs and history of all backup operations, including timestamps, sizes, and status (successful, failed, partial).</p>
        </div>
        {/* Backup Restoration Tool */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Backup Restoration Tool:</h3>
          <p>Interface for browsing and restoring data from backups, with options to perform complete or selective restoration.</p>
        </div>
      </div>
    </div>
  );
};

export default BackupScheduling;
