import React, { useState } from 'react';

const ActivityLogs = ({ activityLogs }) => {
  const [actionFilter, setActionFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [selectedLog, setSelectedLog] = useState(null);

  const handleActionFilterChange = (e) => {
    setActionFilter(e.target.value);
  };

  const handleDateRangeFilterChange = (e) => {
    setDateRangeFilter(e.target.value);
  };

  const handleUserFilterChange = (e) => {
    setUserFilter(e.target.value);
  };

  const handleLogItemClick = (log) => {
    setSelectedLog(log);
  };

  const handleViewLogDetails = (log) => {
    setSelectedLog(log === selectedLog ? null : log);
  };

  return (
    <div className="bg-pastalblue activity-logs-container bg-gray-100 p-4 mt-6 rounded-md shadow-md">
      <h1 className="text-2xl font-bold mb-4">User Activity Logs</h1>

      {/* Filters Section */}
      <div className="filters-section mb-4">
        <label className="block mb-2">Action Type Filter:</label>
        <select
          value={actionFilter}
          onChange={handleActionFilterChange}
          className="px-3 py-2 border rounded-md"
        >
          <option value="">All Actions</option>
          <option value="postCreation">Post Creation</option>
          <option value="comment">Comment</option>
          <option value="login">Login</option>
        </select>

        <label className="block mt-4 mb-2">Date Range Filter:</label>
        <input
          type="date"
          value={dateRangeFilter}
          onChange={handleDateRangeFilterChange}
          className="px-3 py-2 border rounded-md"
        />

        <label className="block mt-4 mb-2">User Filter:</label>
        <input
          type="text"
          value={userFilter}
          onChange={handleUserFilterChange}
          placeholder="Enter username"
          className="px-3 py-2 border rounded-md"
        />
      </div>

      {/* Log Viewer Section */}
      <div className="log-viewer-section">
        <h2 className="text-xl Teko sans-serif font-bold mb-2">Activity Logs</h2>
        <ul className="list-disc space-y-2">
          {activityLogs && activityLogs.length > 0 ? (
            activityLogs.map((log, index) => (
              <li key={index} className="text-blue-500">
                <button
                  className="mr-2 text-blue-500 underline"
                  onClick={() => handleViewLogDetails(log)}
                >
                  {selectedLog === log ? 'Hide' : 'View'}
                </button>
                {log.action} - {log.timestamp} by {log.user}
                {selectedLog === log && (
                  <div className="ml-4">
                    <p><strong>Action:</strong> {log.action}</p>
                    <p><strong>Timestamp:</strong> {log.timestamp}</p>
                    <p><strong>User:</strong> {log.user}</p>
                    <p><strong>Details:</strong> {log.details}</p>
                  </div>
                )}
              </li>
            ))
          ) : (
            <p>No activity logs found.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ActivityLogs;
