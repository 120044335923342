import React from "react";
import { Link } from "react-router-dom";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";

const Dashboard = () => {
  const data = [
    {
      heading: "Users",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Users/Users",
      count: false,
    },
    {
      heading: "Finance",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Finance/Finance",
      count: false,
    },
    {
      heading: "Posts",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Posts/Posts",
      count: false,
    },
    {
      heading: "Performance",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Performance/Performance",
    },
    {
      heading: "Security",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Security/Security",
      count: false,
    },
    {
      heading: "Activity",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Activity/Activity",
    },
    {
      heading: "SEO",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/SEO/SEO",
      count: false,
    },
    {
      heading: "Support",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Support/Support",
      count: false,
    },
    {
      heading: "Integrations",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Integrtions/Integrations",
      count: false,
    },

    {
      heading: "Additional",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Additional/Additional",
      count: false,
    },

    {
      heading: "Visualization",
      icon: (
        <i className="text-[20px] cursor-pointer hover:text-brightblue"></i>
      ),
      link: "/admin-panel/Visualization/Visualization",
      count: false,
    },
  ];

  return (
    <div className="lg:ml-[5%] ">
      <div className="grid grid-cols-2 md:grid-cols-2 xmd:grid-cols-3 xl:grid-cols-4 w-[320px] xmd:w-[400px] xl:w-[597px] gap-6 md:gap-4 ">
        {data?.map((item, index) => (
          <div key={index} className="col-span-1 w-[135px] relative">
            {item.count && (

              <div className="w-[25px] h-[25px] rounded-full z-10 top-2 left-2 bg-primaryblue text-white absolute flex justify-center items-center">
                <p className="text-[12px]">{item.number}</p>
              </div>
            )}
            <Link to={item.link}>
              <div className="flex flex-col items-center bg-pastalblue text-offblack rounded-2xl py-14">
                {item.icon}
                <SecondaryHeading variant="Paragrapheading" className="mt-3 text-offblack">
                  {item.heading}
                </SecondaryHeading>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
