import React from "react";

const TeamProfiles = () => {
  return (
    <div className="p-8">
      {/* Team Details Subpage */}
      <div className="mb-8">
      <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Team Profiles</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          <div className="mb-4">
          <h2 className="text-2xl ont-teko font-semibold mb-4">Team Details Subpage</h2>
            <h3 className="text-lg ont-teko font-semibold mb-2">Role Information:</h3>
            <p className="ont-teko">Displays the specific roles and responsibilities assigned to each team member.</p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Activity Logs:</h3>
            <p className="ont-teko">Shows a detailed log of actions taken by the team user within the platform.</p>
          </div>
          <div>
            <h3 className="text-lg ont-teko font-semibold mb-2">Permissions Overview:</h3>
            <p className="ont-teko">Lists the specific permissions or access rights granted to the members.</p>
          </div>
        </div>
      </div>

      {/* Add/Edit Team Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <div className="mb-4">
          <h2 className="text-2xl ont-teko font-semibold mb-4">Add/Edit Team Subpage</h2>
          <h3 className="text-lg ont-teko font-semibold mb-2">Profile Creation Form:</h3>
          <p className="ont-teko">A form to add new team member profiles, including role assignment and permission settings.</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg ont-teko font-semibold mb-2">Profile Editing Form:</h3>
          <p className="ont-teko">Allows for updating existing member profiles, modifying roles, and permissions as needed.</p>
        </div>
      </div>
    </div>
  );
};

export default TeamProfiles;
