import React from "react";
import Section1 from "../../sections/Settings/ThemeSetting/Section1";
import Heading from '../../components/common/Typography/Heading'


const ThemeSetting = () => {
  return (
    <>
    <div>
      <Heading variant={"h4"}>
      Settings   
      </Heading>
      <div>
        <Section1/>
      </div>
    </div>
  </>
  );
};

export default ThemeSetting;
