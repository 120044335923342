import React from "react";

const SystemHealthDashboard = () => {
  return (
    <div className="p-8">
      {/* Performance Monitoring Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">System Health Dashboard</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Real-Time Performance Metrics */}
          <div className="mb-4">
            <h3 className="text-2xl font-semibold mb-4">Performance Monitoring Subpage</h3>
            <h4 className="text-lg font-semibold mb-2">Real-Time Performance Metrics:</h4>
            <p>Displays live data on CPU usage, memory consumption, network bandwidth, and disk activity.</p>
          </div>
          {/* System Uptime Tracker */}
          <div>
            <h4 className="text-lg font-semibold mb-2">System Uptime Tracker:</h4>
            <p>Monitors and reports on the uptime of various system components, identifying any downtime occurrences.</p>
          </div>
        </div>
      </div>

      {/* Resource Utilization Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Resource Utilization Subpage</h2>
        {/* Detailed Resource Analysis */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Resource Utilization Subpage</h3>
          <h4 className="text-lg font-semibold mb-2">Detailed Resource Analysis:</h4>
          <p>Provides in-depth analytics on the utilization of system resources, helping to pinpoint areas of high demand or potential bottlenecks.</p>
        </div>
        {/* Capacity Planning Tools */}
        <div>
          <h4 className="text-lg font-semibold mb-2">Capacity Planning Tools:</h4>
          <p>Offers tools for predicting future system load and resource requirements based on historical data and usage trends.</p>
        </div>
      </div>
    </div>
  );
};

export default SystemHealthDashboard;
