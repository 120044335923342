import React from 'react'
import Section1 from '../../sections/Monitoring/AlertNotifications/Section1'
import Heading from '../../components/common/Typography/Heading'

const AlertNotifications = () => {
  return (
    <>
    <div>
      <Heading variant={"h4"}>
       Monitoring
      </Heading>
      <div>
        <Section1/>
      </div>
    </div>
  </>
  )
}

export default AlertNotifications