/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Dashboard from "../src/pages/Dashboard/Dashboard";
import Login from "./pages/Login";



//  User Management 
import Profiles from "./pages/UserManagement/Profiles"
import Logs from "./pages/UserManagement/Logs"
import SearchAndFilters from "./pages/UserManagement/SearchAndFilters"

//  People 
import RolesPermissions from "./pages/People/RolesPermissions"
import TeamProfiles from "./pages/People/TeamProfiles"

//  Post Management 
import PostListings from "./pages/PostsManagement/PostListings"
import CategoryManagement from "./pages/PostsManagement/CategoryManagement"

//  Security and Fraud 
import VerificationTools from "./pages/SecurityFraud/VerificationTools"
import ComplianceReporting from "./pages/SecurityFraud/ComplianceReporting"

//  Finance Management
import SubscriptionManagement from "./pages/Finance/SubscriptionManagement"
import TransactionOverview from "./pages/Finance/TransactionOverview"

//  Reviews & Rating
import FlaggedReviews from "./pages/ReviewsRating/FlaggedReviews"
import ReviewManagement from "./pages/ReviewsRating/ReviewManagement"

//  Reports & Metrics
import AnalyticsDashboards from "./pages/ReportsMetrics/AnalyticsDashboards"
import ExportableReports from "./pages/ReportsMetrics/ExportableReports"

//  Notifications
import AutomatedNotifications from "./pages/Notifications/AutomatedNotifications"
import NotificationSettings from "./pages/Notifications/NotificationSettings"


//  Intergrations
import IntegrationManagement from "./pages/Integrations/IntegrationManagement"
import ApiManagement from "./pages/Integrations/ApiManagement"


//  Content Management
import StaticPageEditor from "./pages/ContentManagement/StaticPageEditor"
import BlogManagement from "./pages/ContentManagement/BlogManagement"

//  SEO Tools
import KeywordTracking from "./pages/SEOTools/KeywordTracking"
import SeoOptimization from "./pages/SEOTools/SeoOptimization"

//  Marketing
import EmailCompaign from "./pages/Marketing/EmailCompaign"
import AffilateProgram from "./pages/Marketing/AffilateProgram"

//  Support
import LiveChatSystem from "./pages/Support/LiveChatSystem"
import TicketingSystem from "./pages/Support/TicketingSystem"

//  Settings
import ThemeSetting from "./pages/Settings/ThemeSetting"
import FeatureFunctionality from "./pages/Settings/FeatureFunctionality"
import UserExperience from "./pages/Settings/UserExperience"

//  Data Management 
import BackupScheduling from "./pages/DataManagement/BackupScheduling"
import CloudStorage from "./pages/DataManagement/CloudStorage"


//  Monitoring
import SystemHealth from "./pages/Monitoring/SystemHealth"
import AlertNotifications from "./pages/Monitoring/AlertNotifications"

//  Dashboard inner pages

import Users from "./sections/Dashboard/Users/Users";
import Finance from "./sections/Dashboard/Finance/Finance";
import Posts from "./sections/Dashboard/Posts/Posts";
import Performance from "./sections/Dashboard/Performance/Performance";
import Security from "./sections/Dashboard/Security/Security";
import Activity from "./sections/Dashboard/Activity/Activity";
import SEO from "./sections/Dashboard/SEO/seo";
import Support from "./sections/Dashboard/Support/Support";
import Integrations from "./sections/Dashboard/Integrations/Integrations";
import Additional from "./sections/Dashboard/Additional/Additional";
import Visualization from "./sections/Dashboard/Visualization/Visualization";



function App() {
  return (
    <>
      <ScrollTop />
      <div className="App">
        <Routes>
        <Route path="/" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route path="admin-panel/dashboard" element={<Dashboard />} />
            <Route path="admin-panel/dashboard/test" element={< ></>} />


            {/* User Management Routes */}
            <Route path="admin-panel/dashboard/user-management/profiles" element={<Profiles/>} />
            <Route path="admin-panel/dashboard/user-management/logs" element={<Logs/>} />
            <Route path="admin-panel/dashboard/user-management/SearchAndFilters" element={<SearchAndFilters/>} />

            {/* People */}
            <Route path="admin-panel/dashboard/people/RolesPermissions" element={<RolesPermissions/>} />
            <Route path="admin-panel/dashboard/people/TeamProfiles" element={<TeamProfiles/>} />

             {/* Posts Management Routes */}
             <Route path="admin-panel/dashboard/posts-management/CategoryManagement" element={<CategoryManagement/>} /> 
             <Route path="admin-panel/dashboard/posts-management/PostListings" element={<PostListings/>} />

             {/* Security And Fraud */}
             <Route path="admin-panel/dashboard/security-fraud/VerificationTools" element={<VerificationTools/>} /> 
             <Route path="admin-panel/dashboard/security-fraud/ComplianceReporting" element={<ComplianceReporting/>} />


            {/* Finance Management Routes */}
            <Route path="admin-panel/dashboard/finance/subscription-management" element={<SubscriptionManagement/>} />
            <Route path="admin-panel/dashboard/finance/transaction-overview" element={<TransactionOverview/>} />

            {/* Review and Rating Routes */}
            <Route path="admin-panel/dashboard/reviews-rating/flagged-reviews" element={<FlaggedReviews/>} />
            <Route path="admin-panel/dashboard/reviews-rating/review-management" element={<ReviewManagement/>} />

            {/* Reports & Metrics Routes */}
            <Route path="admin-panel/dashboard/reports-metrics/analytics-dashboard" element={<AnalyticsDashboards/>} />
            <Route path="admin-panel/dashboard/reports-metrics/exportable-reports" element={<ExportableReports/>} />

            {/* Notifications */}
            <Route path="admin-panel/dashboard/notification/notification-settings" element={<NotificationSettings/>} />
            <Route path="admin-panel/dashboard/notification/automated-notifications" element={<AutomatedNotifications/>} />

           {/* Integrations */}
            <Route path="admin-panel/dashboard/integrations/integration-management" element={<IntegrationManagement/>} />
            <Route path="admin-panel/dashboard/integrations/api-management" element={<ApiManagement/>} />


             {/* Content Management Routes */}
            <Route path="admin-panel/dashboard/content-management/static-page-editor" element={<StaticPageEditor/>} />
            <Route path="admin-panel/dashboard/content-management/blog-management" element={<BlogManagement/>} />

            {/* SEO Tools */}
            <Route path="admin-panel/dashboard/seo-tools/keyword-tracking" element={<KeywordTracking/>} />
            <Route path="admin-panel/dashboard/seo-tools/seo-optimization" element={<SeoOptimization/>} />

            {/* Marketing */}
            <Route path="admin-panel/dashboard/marketing/email-compaign" element={<EmailCompaign/>} />
            <Route path="admin-panel/dashboard/marketing/affilate-program" element={<AffilateProgram/>} />

             {/* Support Routes */}
             <Route path="admin-panel/dashboard/support/live-chat-system" element={<LiveChatSystem/>} />
            <Route path="admin-panel/dashboard/support/ticketing-system" element={<TicketingSystem/>} />

            {/* Settings Routes */}
            <Route path="admin-panel/dashboard/settings/theme-setting" element={<ThemeSetting/>} />
            <Route path="admin-panel/dashboard/settings/feature-functionality" element={<FeatureFunctionality/>} />
            <Route path="admin-panel/dashboard/settings/user-experience" element={<UserExperience/>} />

            {/* Data Management Routes */}
            <Route path="admin-panel/dashboard/data-management/backup-scheduling" element={<BackupScheduling/>} />
            <Route path="admin-panel/dashboard/data-management/cloud-storage" element={<CloudStorage/>} />


            {/* Monitoring Routes */}
            <Route path="admin-panel/dashboard/monitoring/system-health" element={<SystemHealth/>} />
            <Route path="admin-panel/dashboard/monitoring/alert-notifications" element={<AlertNotifications/>} />

            {/* Dashboard Inner pages */}
            <Route path="admin-panel/Users/Users" element={<Users/>} />
            <Route path="admin-panel/Finance/Finance" element={<Finance/>} />
            <Route path="admin-panel/Posts/Posts" element={<Posts/>} />
            <Route path="admin-panel/Performance/Performance" element={<Performance/>} />
            <Route path="admin-panel/Security/Security" element={<Security/>} />
            <Route path="admin-panel/Activity/Activity" element={<Activity/>} />
            <Route path="admin-panel/SEO/SEO" element={<SEO/>} />
            <Route path="admin-panel/Support/Support" element={<Support/>} />
            <Route path="admin-panel/Integrtions/Integrations" element={<Integrations/>} />
            <Route path="admin-panel/Additional/Additional" element={<Additional/>} />
            <Route path="admin-panel/Visualization/Visualization" element={<Visualization/>} />



          </Route>
        </Routes>
      </div>
    </>
  );
}
export default App;

export const ScrollTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname !== "/") {
      const time = setTimeout(() => {
        window.scrollTo(0, 0);
        clearTimeout(time);
      }, 200);
    }
  }, [pathname]);
  return null;
};
