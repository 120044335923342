import React from "react";

const ExportableReports = () => {
  return (
    <div className="p-8">
      {/* Report Generation Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Exportable Reports</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Pre-defined Report Templates */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Report Generation Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Pre-defined Report Templates:</h3>
            <p>Selection of common report formats for quick generation.</p>
          </div>
          {/* Custom Report Builder */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Custom Report Builder:</h3>
            <p>Tool for creating custom reports based on specific data sets and metrics.</p>
          </div>
          {/* Export Options */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Export Options:</h3>
            <p>Ability to export reports in various formats (PDF, Excel, CSV, etc.) for sharing or offline analysis.</p>
          </div>
        </div>
      </div>

      {/* Enhancements and Suggestions */}
      <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* Advanced Data Visualization Tools */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Advanced Data Visualization Tools:</h3>
          <p>Incorporate sophisticated data visualization capabilities, such as dynamic charts, heat maps, and trend lines, to make data interpretation more intuitive and insightful.</p>
        </div>
        {/* Real-Time Reporting Feature */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Real-Time Reporting Feature:</h3>
          <p>Develop capabilities for real-time data reporting, allowing administrators to view up-to-the-minute metrics and analytics.</p>
        </div>
        {/* Automated Report Scheduling */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Automated Report Scheduling:</h3>
          <p>Enable administrators to schedule regular report generation and delivery, ensuring consistent updates on key metrics.</p>
        </div>
        {/* Integrated Data Sources */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Integrated Data Sources:</h3>
          <p>Facilitate integration with external data sources to enrich internal analytics and provide a more comprehensive view of performance.</p>
        </div>
        {/* Interactive Report Customization */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Interactive Report Customization:</h3>
          <p>Enhance the custom report builder with drag-and-drop elements and real-time previews, making report customization more user-friendly and efficient.</p>
        </div>
      </div>
    </div>
  );
};

export default ExportableReports;
