import React from 'react'
import Section1 from '../../sections/ReportsMetrics/AnalyticsDashboards/Section1'
import Heading from '../../components/common/Typography/Heading'

const AnalyticsDashboards = () => {
  return (
    <>
    <div>
      <Heading variant={"h4"}>
      Reports & Metrics
      </Heading>
      <div>
        <Section1/>
      </div>
    </div>
  </>
  )
}

export default AnalyticsDashboards