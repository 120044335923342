import React from "react";

const NotificationSettings = () => {
  return (
    <div className="p-8">
      {/* Configuration Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Notification Settings</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Trigger Setup */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Configuration Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Trigger Setup:</h3>
            <p>Allows administrators to define events that trigger notifications, such as new user registration, transaction completion, or system alerts.</p>
          </div>
          {/* Audience Segmentation Tool */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Audience Segmentation Tool:</h3>
            <p>Enables targeting specific user groups based on demographics, behavior, or custom criteria for each notification.</p>
          </div>
          {/* Delivery Channel Selection */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Delivery Channel Selection:</h3>
            <p>Choice of notification delivery methods, including email, SMS, push notifications, or in-app messages.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
