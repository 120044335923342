import React, { useState, useEffect } from 'react';

const IntegrationAndAPIWidgets = () => {
  // State for third-party integration status
  const [integrationStatus, setIntegrationStatus] = useState({
    service1: 'Online',
    service2: 'Offline',
    service3: 'Online',
    service4: 'Online',
  });

  // State for API usage overview
  const [apiUsage, setApiUsage] = useState({
    api1: { name: 'Authentication API', calls: 1500 },
    api2: { name: 'Data Retrieval API', calls: 2300 },
    api3: { name: 'Analytics API', calls: 1200 },
  });

  // Simulate updating integration status and API usage
  useEffect(() => {
    const updateIntegrationStatus = () => {
      // Simulated random status updates
      const services = ['Online', 'Offline', 'Degraded'];
      const updatedStatus = {
        service1: services[Math.floor(Math.random() * services.length)],
        service2: services[Math.floor(Math.random() * services.length)],
        service3: services[Math.floor(Math.random() * services.length)],
        service4: services[Math.floor(Math.random() * services.length)],
      };
      setIntegrationStatus(updatedStatus);
    };

    const updateApiUsage = () => {
      // Simulated random API usage updates
      const updatedUsage = {
        api1: { name: 'Authentication API', calls: Math.floor(Math.random() * 3000) },
        api2: { name: 'Data Retrieval API', calls: Math.floor(Math.random() * 3000) },
        api3: { name: 'Analytics API', calls: Math.floor(Math.random() * 3000) },
      };
      setApiUsage(updatedUsage);
    };

    // Initial data fetch
    updateIntegrationStatus();
    updateApiUsage();

    // Simulated interval to update data every 20 seconds
    const intervalId = setInterval(() => {
      updateIntegrationStatus();
      updateApiUsage();
    }, 20000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8">Integration and API</h1>

      {/* Third-Party Integration Status Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-lg Teko sans-serif font-bold mb-4">Third-Party Integration Status</h2>
        <ul className="divide-y divide-gray-200">
          {Object.entries(integrationStatus).map(([service, status]) => (
            <li key={service} className="py-2">
              <span className="font-semibold">{service}: </span>
              <span className={status === 'Online' ? 'text-green-600' : 'text-red-600'}>{status}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* API Usage Overview Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-lg Teko sans-serif font-bold mb-4">API Usage Overview</h2>
        <ul className="divide-y divide-gray-200">
          {Object.values(apiUsage).map((api) => (
            <li key={api.name} className="py-2">
              <p className="font-semibold">{api.name}</p>
              <p className="text-gray-600">Calls: {api.calls}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default IntegrationAndAPIWidgets;
