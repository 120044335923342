import React from "react";

const KeywordTracking = () => {
  return (
    <div className="p-8">
      {/* Keyword Performance Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Keyword Tracking</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Ranking Dashboard */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Keyword Performance Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Ranking Dashboard:</h3>
            <p>Displays current keyword rankings across different search engines and tracks changes over time.</p>
          </div>
          {/* Keyword Analysis Tool */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Keyword Analysis Tool:</h3>
            <p>Provides insights into keyword performance, search volume, competition level, and potential opportunities for ranking improvement.</p>
          </div>
          {/* Historical Data Analysis */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Historical Data Analysis:</h3>
            <p>Allows for viewing and analyzing historical keyword performance trends to understand changes in SEO effectiveness.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordTracking;
