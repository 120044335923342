import React, { useState, useEffect } from 'react';

const SupportAndFeedbackWidgets = () => {
  // State for support ticket overview
  const [supportTickets, setSupportTickets] = useState({
    totalTickets: 0,
    openTickets: 0,
    closedTickets: 0,
  });

  // State for user feedback stream
  const [userFeedbacks, setUserFeedbacks] = useState([]);

  // Simulate updating support tickets and user feedbacks
  useEffect(() => {
    // Simulated data updates
    const updateSupportTickets = () => {
      const total = Math.floor(Math.random() * 100); // Random total tickets
      const open = Math.floor(Math.random() * total); // Random open tickets
      const closed = total - open;
      setSupportTickets({ totalTickets: total, openTickets: open, closedTickets: closed });
    };

    const updateFeedbacks = () => {
      const feedbacks = [
        { id: 1, user: 'John Doe', message: 'Great service!', date: '2024-04-01' },
        { id: 2, user: 'Jane Smith', message: 'Need improvement on response time.', date: '2024-04-02' },
        { id: 3, user: 'Sam Johnson', message: 'Could not resolve my issue.', date: '2024-04-03' },
        { id: 4, user: 'Emily Brown', message: 'Very helpful support team!', date: '2024-04-04' },
        { id: 5, user: 'Alex Johnson', message: 'Quick response and resolution.', date: '2024-04-05' },
      ];
      setUserFeedbacks(feedbacks);
    };

    // Initial data fetch
    updateSupportTickets();
    updateFeedbacks();

    // Simulated interval to update data every 30 seconds
    const intervalId = setInterval(() => {
      updateSupportTickets();
      updateFeedbacks();
    }, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8">Support and Feedback</h1>

      {/* Support Ticket Overview Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-lg Teko sans-serif font-bold mb-4">Support Ticket Overview</h2>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-lg Teko sans-serif font-semibold">Total Tickets</p>
            <p className="text-2xl Teko font-bold">{supportTickets.totalTickets}</p>
          </div>
          <div>
            <p className="text-lg Teko sans-serif font-semibold">Open Tickets</p>
            <p className="text-2xl Teko font-bold">{supportTickets.openTickets}</p>
          </div>
          <div>
            <p className="text-lg Teko sans-serif font-semibold">Closed Tickets</p>
            <p className="text-2xl Teko font-bold">{supportTickets.closedTickets}</p>
          </div>
        </div>
      </div>

      {/* User Feedback Stream Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-lg Teko sans-serif font-bold mb-4">User Feedback Stream</h2>
        <ul className="divide-y divide-gray-200">
          {userFeedbacks.map((feedback) => (
            <li key={feedback.id} className="py-4">
              <p className="text-lg Teko sans-serif font-semibold">{feedback.user}</p>
              <p className="text-gray-600 mb-2">{feedback.message}</p>
              <p className="text-gray-500 text-sm">{feedback.date}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SupportAndFeedbackWidgets;
