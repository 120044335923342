import React from "react";

const LiveChatSystem = () => {
  return (
    <div className="p-8">
      {/* Chat Management Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Live Chat System</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Chat Management Subpage</h2>
          {/* Integration Tools */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Integration Tools:</h3>
            <p>Features for integrating live chat software with the website, including customization options for appearance and behaviour.</p>
          </div>
          {/* Chat Dashboard */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Chat Dashboard:</h3>
            <p>Real-time monitoring of ongoing chat sessions, with capabilities to join, observe, or take over conversations as needed.</p>
          </div>
          {/* Operator Performance Metrics */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Operator Performance Metrics:</h3>
            <p>Analytics on chat operators’ performance, including response times, resolution rates, and customer satisfaction scores.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChatSystem;
