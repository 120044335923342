import React from "react";

const VerificationTools = () => {
  return (
    <div className="p-8">
      {/* Integration Setup Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Verification Tools</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Service Integration List */}
          <div className="mb-4">
            <h2 className="text-2xl ont-teko font-semibold mb-4">Integration Setup Subpage</h2>
            <h3 className="text-lg ont-teko font-semibold mb-2">Service Integration List:</h3>
            <p className="ont-teko">Displays available verification services and their status (integrated, pending, or not integrated).</p>
          </div>
          {/* Integration Configuration */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Integration Configuration:</h3>
            <p className="ont-teko">Interface for setting up and configuring integration parameters with external verification services.</p>
          </div>
          {/* Integration Logs */}
          <div>
            <h3 className="text-lg ont-teko font-semibold mb-2">Integration Logs:</h3>
            <p className="ont-teko">Shows a log of all verification attempts, including timestamp, user details, and outcome (success, failure, reason for failure).</p>
          </div>
        </div>
      </div>

      {/* Verification Management Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* User Verification Status */}
        <div className="mb-4">
          <h2 className="text-2xl ont-teko font-semibold mb-4">Verification Management Subpage</h2>
          <h3 className="text-lg ont-teko font-semibold mb-2">User Verification Status:</h3>
          <p className="ont-teko">Allows administrators to view and manage the verification status of users, including manual overrides or re-verification requests.</p>
        </div>
      </div>
    </div>
  );
};

export default VerificationTools;
