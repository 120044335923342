import React, { useState, useEffect } from 'react';

const AdditionalWidgets = () => {
  // State for subscription status
  const [subscriptionStatus, setSubscriptionStatus] = useState('Active');

  // State for category activity
  const [categoryActivity, setCategoryActivity] = useState([
    { category: 'Technology', posts: 120 },
    { category: 'Fashion', posts: 85 },
    { category: 'Food', posts: 65 },
    { category: 'Travel', posts: 50 },
  ]);

  // State for real-time traffic analysis
  const [trafficData, setTrafficData] = useState({
    visitors: 0,
    sourceOrigins: ['Direct', 'Referral', 'Organic Search', 'Social Media'],
    activePage: 'Home',
  });

  // Simulate updating subscription status, category activity, and traffic analysis
  useEffect(() => {
    const updateSubscriptionStatus = () => {
      const statuses = ['Active', 'Pending', 'Cancelled'];
      const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
      setSubscriptionStatus(randomStatus);
    };

    const updateCategoryActivity = () => {
      const updatedCategories = categoryActivity.map((category) => ({
        ...category,
        posts: category.posts + Math.floor(Math.random() * 10), // Simulate post activity increase
      }));
      setCategoryActivity(updatedCategories);
    };

    const updateTrafficData = () => {
      const updatedTraffic = {
        visitors: Math.floor(Math.random() * 1000), // Simulate random visitors count
        sourceOrigins: trafficData.sourceOrigins, // Source origins remain the same
        activePage: trafficData.activePage, // Active page remains the same
      };
      setTrafficData(updatedTraffic);
    };

    // Initial data fetch
    updateSubscriptionStatus();
    updateCategoryActivity();
    updateTrafficData();

    // Simulated interval to update data every 15 seconds
    const intervalId = setInterval(() => {
      updateSubscriptionStatus();
      updateCategoryActivity();
      updateTrafficData();
    }, 15000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8">Additional Comprehensive </h1>

      {/* Subscription Status Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Subscription Status</h2>
        <p className="text-xl font-semibold">{subscriptionStatus}</p>
      </div>

      {/* Category Activity Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Category Activity</h2>
        <ul className="divide-y divide-gray-200">
          {categoryActivity.map((category, index) => (
            <li key={index} className="py-2">
              <span className="font-semibold">{category.category}: </span>
              <span className="text-gray-600">{category.posts} posts</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Real-Time Traffic Analysis Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Real-Time Traffic Analysis</h2>
        <p className="text-xl font-semibold">Visitors: {trafficData.visitors}</p>
        <p className="text-xl Teko sans-serif font-semibold mb-2">Active Page: {trafficData.activePage}</p>
        <p className="text-xl Teko sans-serif font-semibold">Source Origins:</p>
        <ul className="divide-y divide-gray-200">
          {trafficData.sourceOrigins.map((origin, index) => (
            <li key={index} className="py-2">
              {origin}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdditionalWidgets;
