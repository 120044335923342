import React from "react";

const TransactionOverview = () => {
  return (
    <div className="p-8">
      {/* Detailed Transaction Log Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Transaction Overview</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Transaction List */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Detailed Transaction Log Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Transaction List:</h3>
            <p>A comprehensive view of all financial transactions, filterable by date, type, user, and amount.</p>
          </div>
          {/* Export Function */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Export Function:</h3>
            <p>Ability to export transaction data in various formats (CSV, PDF, etc.) for offline analysis or record-keeping.</p>
          </div>
        </div>
      </div>

      {/* Financial Analytics Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* Revenue Dashboard */}
        <div className="mb-4">
          <h2 className="text-2xl font-semibold mb-4">Financial Analytics Subpage</h2>
          <h3 className="text-lg font-semibold mb-2">Revenue Dashboard:</h3>
          <p>Displays key financial metrics, including revenue streams, expense tracking, and net income.</p>
        </div>
        {/* Trend Analysis */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Trend Analysis:</h3>
          <p>Offers insights into financial trends over time, with predictive analytics for future projections.</p>
        </div>
        {/* Customizable Reports */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Customizable Reports:</h3>
          <p>Enables creation of custom financial reports based on selected criteria and metrics.</p>
        </div>
      </div>
    </div>
  );
};

export default TransactionOverview;
