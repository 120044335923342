import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const UserGeographicDistributionMap = () => {
  const [userLocations, setUserLocations] = useState([]);
  const mapRef = useRef(null);

  // Sample user data with location, activity level, and market penetration
  const initialUserLocations = [
    { id: 1, name: 'John Doe', lat: 40.73061, lng: -73.935242, activity: 'High', marketPenetration: 90 },
    { id: 2, name: 'Jane Smith', lat: 51.509865, lng: -0.118092, activity: 'Medium', marketPenetration: 60 },
    { id: 3, name: 'Sam Johnson', lat: 48.8566, lng: 2.3522, activity: 'Low', marketPenetration: 40 },
  ];

  useEffect(() => {
    setUserLocations(initialUserLocations);

    // Simulate updating user locations and data
    const intervalId = setInterval(() => {
      const updatedUserLocations = initialUserLocations.map((location) => ({
        ...location,
        activity: Math.random() > 0.5 ? 'High' : Math.random() > 0.3 ? 'Medium' : 'Low',
        marketPenetration: Math.floor(Math.random() * 100),
      }));
      setUserLocations(updatedUserLocations);
    }, 30000); // Update every 30 seconds

    return () => clearInterval(intervalId);
  }, []);

  // Define custom marker icons based on activity level
  const getMarkerIcon = (activity) => {
    let iconUrl;
    switch (activity) {
      case 'High':
        iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png';
        break;
      case 'Medium':
        iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png';
        break;
      case 'Low':
        iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png';
        break;
      default:
        iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png';
        break;
    }

    return new L.Icon({
      iconUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });
  };

  const MapUpdater = () => {
    const map = useMap();

    useEffect(() => {
      const onResize = () => {
        map.invalidateSize();
      };

      window.addEventListener('resize', onResize);
      map.invalidateSize();

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, [map]);

    return null;
  };

  return (
    <div className="container mx-auto p-4 h-full">
      <h1 className="text-3xl font-bold mb-8">Geographical Data Visualization</h1>
      <h2 className="text-xl font-bold mb-8">User Geographic Distribution Map</h2>

      <div className="h-full" style={{ height: '500px' }}>
        <MapContainer center={[20, 0]} zoom={2} style={{ height: '100%', width: '100%' }} ref={mapRef}>
          <MapUpdater />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {userLocations.map((location) => (
            <Marker
              key={location.id}
              position={[location.lat, location.lng]}
              icon={getMarkerIcon(location.activity)}
            >
              <Tooltip direction="top" offset={[0, -25]}>
                <div>
                  <p><strong>Name:</strong> {location.name}</p>
                  <p><strong>Activity:</strong> {location.activity}</p>
                  <p><strong>Market Penetration:</strong> {location.marketPenetration}%</p>
                </div>
              </Tooltip>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default UserGeographicDistributionMap;
