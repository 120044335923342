import React from "react";

const AnalyticsDashboards = () => {
  return (
    <div className="p-8">
      {/* Customizable Dashboard Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Analytics Dashboards</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* User Behaviour Reports */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Customizable Dashboard Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">User Behaviour Reports:</h3>
            <p>Interactive reports displaying user activities, trends, and patterns.</p>
          </div>
          {/* Post Engagement Reports */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Post Engagement Reports:</h3>
            <p>Metrics on how users interact with content, including views, likes, shares, and comments.</p>
          </div>
          {/* Financial Performance Reports */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Financial Performance Reports:</h3>
            <p>Analysis of revenue streams, costs, and overall financial health.</p>
          </div>
          {/* System Usage Reports */}
          <div>
            <h3 className="text-lg font-semibold mb-2">System Usage Reports:</h3>
            <p>Data on platform usage, including peak times, user demographics, and device types.</p>
          </div>
        </div>
      </div>

      {/* Dashboard Customization Tool */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Dashboard Customization Tool</h2>
        <p>Allows administrators to create and modify dashboards to focus on specific metrics or data points of interest.</p>
      </div>
    </div>
  );
};

export default AnalyticsDashboards;
