// import useAuth from "../../Hooks/useAuth";

function generateRandomNumber() {
  return Math.random() * 99999;
}
const useSidebarData = (props) => {
  // const { setAuth } = useAuth();
  return [
    {
      linkName: "Dashboard",
      link: "admin-panel/dashboard",
    },
    {
      linkName: "Users",
      subMenu: [
        {
          linkName: "Profile Overview",
            link: "/admin-panel/dashboard/user-management/profiles",
            id: generateRandomNumber(),
        },
        {
          linkName: "User Activity Logs",
            link: "/admin-panel/dashboard/user-management/logs",
            id: generateRandomNumber(),
        },
        {
          linkName: "User Search & Filters",
            link: "/admin-panel/dashboard/user-management/SearchAndFilters",
          id: generateRandomNumber(),
        },
       
      ],
    },
    

    {
      linkName: "People",
      subMenu: [
        {
          linkName: "Team Profiles",
            link: "/admin-panel/dashboard/people/TeamProfiles",
          id: generateRandomNumber(),
        },
        {
          linkName: "Roles and Permissions",
          link: "/admin-panel/dashboard/people/RolesPermissions",
          id: generateRandomNumber(),
        },
       
      ],
    },
    
    {
      linkName: "Posts",
      subMenu: [
        {
          linkName: "Post Listings",
            link: "/admin-panel/dashboard/posts-management/PostListings",
          id: generateRandomNumber(),
        },
        {
          linkName: "Category Management",
          link: "/admin-panel/dashboard/posts-management/CategoryManagement",
          id: generateRandomNumber(),
        },
       
      ],
    },

    {
      linkName: "Security & Fraud",
      subMenu: [
        {
          linkName: "Verification Tools",
            link: "/admin-panel/dashboard/security-fraud/VerificationTools",
          id: generateRandomNumber(),
        },
        {
          linkName: "Compliance Reporting",
          link: "/admin-panel/dashboard/security-fraud/ComplianceReporting",
          id: generateRandomNumber(),
        },
       
      ],
    },

    {
      linkName: "Finance",
      subMenu: [
        {
          linkName: "Transaction Overview",
            link: "/admin-panel/dashboard/finance/transaction-overview",
          id: generateRandomNumber(),
        },
        {
          linkName: "Subscription Management",
          link: "/admin-panel/dashboard/finance/subscription-management",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      linkName: "Reviews & Ratings",
      subMenu: [
        {
          linkName: "Review Management",
            link: "/admin-panel/dashboard/reviews-rating/review-management",
          id: generateRandomNumber(),
        },
        {
          linkName: "Flagged Reviews",
          link: "/admin-panel/dashboard/reviews-rating/flagged-reviews",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      linkName: "Reports & Metrics",
      subMenu: [
        {
          linkName: "Analytics Dashboards",
            link: "/admin-panel/dashboard/reports-metrics/analytics-dashboard",
          id: generateRandomNumber(),
        },
        {
          linkName: "Exportable Reports",
          link: "/admin-panel/dashboard/reports-metrics/exportable-reports",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      linkName: "Notifications",
      subMenu: [
        {
          linkName: "Notification Settings",
            link: "/admin-panel/dashboard/notification/notification-settings",
          id: generateRandomNumber(),
        },
        {
          linkName: "Automated Notification Logs",
            link: "/admin-panel/dashboard/notification/automated-notifications",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      linkName: "Integrations",
      subMenu: [
        {
          linkName: "Integration Management",
            link: "/admin-panel/dashboard/integrations/integration-management",
          id: generateRandomNumber(),
        },
        {
          linkName: "API Management",
            link: "/admin-panel/dashboard/integrations/api-management",
          id: generateRandomNumber(),
        },
       
      ],
    },
    {
      linkName: "Content Management",
      subMenu: [
        {
          linkName: "Static Page Editor",
          link: "/admin-panel/dashboard/content-management/static-page-editor",
          id: generateRandomNumber(),
        },
        {
          linkName: "Blog Management",
          link: "/admin-panel/dashboard/content-management/blog-management",
          id: generateRandomNumber(),
        },
       
      ],
    },

    {
      linkName: "SEO Tools",
      subMenu: [
        {
          linkName: "Keyword Tracking",
            link: "/admin-panel/dashboard/seo-tools/keyword-tracking",
          id: generateRandomNumber(),
        },
        {
          linkName: "SEO Optimization",
            link: "/admin-panel/dashboard/seo-tools/seo-optimization",
          id: generateRandomNumber(),
        },
      ],
    },

    {
      linkName: "Marketing",
      subMenu: [
        {
          linkName: "Email Campaign",
            link: "/admin-panel/dashboard/marketing/email-compaign",
          id: generateRandomNumber(),
        },
        {
          linkName: "Affiliate Program Management",
            link: "/admin-panel/dashboard/marketing/affilate-program",
          id: generateRandomNumber(),
        },
       
      ],
    },
    {
      linkName: "Support",
      subMenu: [
        {
          linkName: "Live Chat System",
          link: "/admin-panel/dashboard/support/live-chat-system",
          id: generateRandomNumber(),
        },
        {
          linkName: "Ticketing System",
          link: "/admin-panel/dashboard/support/ticketing-system",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      linkName: "Settings",
      subMenu: [
        {
          linkName: "Theme Settings",
            link: "/admin-panel/dashboard/settings/theme-setting",
          id: generateRandomNumber(),
        },
        {
          linkName: "Feature Toggles and Functionality Management",
            link: "/admin-panel/dashboard/settings/feature-functionality",
          id: generateRandomNumber(),
        },
        {
          linkName: "User Experience and Interaction Settings",
            link: "/admin-panel/dashboard/settings/user-experience",
          id: generateRandomNumber(),
        },
      ],
    },
    
    {
      linkName: "Data Management",
      subMenu: [
        {
          linkName: "Backup Scheduling",
            link: "/admin-panel/dashboard/data-management/backup-scheduling",
          id: generateRandomNumber(),
        },
        {
          linkName: "Cloud Storage Integration",
            link: "/admin-panel/dashboard/data-management/cloud-storage",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      linkName: "Monitoring",
      subMenu: [
        {
          linkName: "System Health Dashboard",
          // link: "/admin-panel/dashboard/test",
          link: "/admin-panel/dashboard/monitoring/system-health",
          id: generateRandomNumber(),
        },
        {
          linkName: "Alerts and Notifications",
            link: "/admin-panel/dashboard/monitoring/alert-notifications",
          id: generateRandomNumber(),
        },
      ],
    },
    
    {
      linkName: "Log out",
      link: "/",
      //   onClick: () => {
      //     localStorage.removeItem("LoginSession");
      //     if (props?.toggleDrawer) {
      //       props.toggleDrawer()
      //     }
      //     setAuth({
      //       user: null,
      //       roles: null,
      //       accessToken: "",
      //     });
      //   },
      //        id: generateRandomNumber(),
    },
  ];
};

export default useSidebarData;
