/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Header = ({ isAdminPanel }) => {
  return (
    <>
      {/* scrolledVersion={scrolledVersion || isOpen} */}
      <HeaderContainer>
        <div
          className={`grid grid-cols-12 py-5 pt-5 ${
            isAdminPanel ? "bg-white" : ""
          } `}
        >
          <div
            className={`${
              isAdminPanel
                ? "flex justify-between col-span-11 lg:col-span-12 lg:px-0 mx-[5%] lg:mx-[10%]"
                : "flex justify-between col-span-11 lg:col-span-12 mx-[5%] lg:mx-[10%]"
            }`}
          >
            <div>
              <Link to={"https://dev.tradesposter.com/"} target="_blank">
                <img
                  className="w-[80px] h-[35px] cursor-pointer"
                  src={require("../../assets/img/trades-poster.png")}
                  alt="trades-poster"
                  onClick={() => window.scrollTo(0, 0)}
                />
              </Link>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = ({ isMobile, children }) => {
  const location = useLocation();
  const isAdminPanel = location.pathname.includes("AdminPanel");
  if (!isAdminPanel) {
    return (
      <div className={` fixed bg-white top-0 w-full  z-40`}>{children}</div>
    );
  }
  return (
    <div
      className={`${isAdminPanel ? "" : "absolute top-0"} w-full z-40 ${
        isMobile ? "bg-white" : "bg-transparent"
      }`}
    >
      {children}
    </div>
  );
};

export default Header;
