import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header/Header"
import Sidebar from "../components/Sidebar/Sidebar"

const Layout = () => {
  const location = useLocation();
  // console.log("roles", auth?.roles[0]?.roleName)
  if (location?.pathname?.includes("admin-panel")) {
    return (
      <main className="App grid grid-rows-[0fr,_1fr]">
        <Header isAdminPanel />
        <div className="lg:grid lg:grid-cols-[0fr,_1fr] h-[calc(100vh_-_120px)] overflow-auto mx-[5%] lg:mx-[10%] xl:mx-[10%] xxl:mx-[11%] xxxl:mx-[12%] mt-[100px] hidden-scrollbar ">
          <div className="hidden lg:block mt-16">
            <Sidebar />
          </div>
          <div className="w-full mt-[65px] lg:ml-[100px]  pb-[100px]">
            <Outlet />
          </div>
        </div>
      </main>
      // <main className="App grid grid-rows-[0fr,_1fr]">
      //   <Header  isAdminPanel/>
      //   <div className="grid grid-cols-[0fr,_1fr] h-screen overflow-auto mx-[10%] hidden-scrollbar">
      //     <div className="hidden lg:block mt-40">
      //       <Sidebar />
      //     </div>
      //     <div className="pb-[100px] ml-10 mt-40">
      //       <Outlet />
      //     </div>
      //   </div>
      // </main>
    );
  } 
};

export default Layout;
