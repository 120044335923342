import React from 'react'
import Section1 from '../../sections/People/TeamProfiles/Section1'
import Heading from '../../components/common/Typography/Heading'

const TeamProfiles = () => {
  return (
    <>
    <div>
      <Heading variant={"h4"}>
      People      
      </Heading>
      <div>
        <Section1/>
      </div>
    </div>
  </>
  )
}

export default TeamProfiles