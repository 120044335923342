import React from "react";

const PostListings = () => {
  return (
    <div className="p-8">
      {/* Manage Posts Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl ont-teko font-semibold mb-4 text-primaryblue">Post Listings</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Posts Overview */}
          <div className="mb-4">
            <h2 className="text-2xl ont-teko font-semibold mb-4">Manage Posts Subpage</h2>
            <h3 className="text-lg ont-teko font-semibold mb-2">Posts Overview:</h3>
            <p className="ont-teko">A sortable and searchable list of all posts, showing title, author, date, and status.</p>
          </div>
          {/* Post Actions */}
          <div className="mb-4">
            <h3 className="text-lg ont-teko font-semibold mb-2">Post Actions:</h3>
            <p className="ont-teko">Options to edit, delete, or highlight posts directly from the list.</p>
          </div>
          {/* Bulk Actions */}
          <div>
            <h3 className="text-lg ont-teko font-semibold mb-2">Bulk Actions:</h3>
            <p className="ont-teko">Capability to perform actions on multiple posts simultaneously, such as bulk deletion, categorization, or status updates.</p>
          </div>
        </div>
      </div>

      {/* Post Analytics Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <div className="mb-4">
          <h2 className="text-2xl ont-teko font-semibold mb-4">Post Analytics Subpage</h2>
          {/* Engagement Overview */}
          <h3 className="text-lg ont-teko font-semibold mb-2">Engagement Overview:</h3>
          <p className="ont-teko">Displays metrics like view counts, likes, shares, and comments for each post.</p>
        </div>
        <div className="mb-4">
          {/* Trend Analysis */}
          <h3 className="text-lg ont-teko font-semibold mb-2">Trend Analysis:</h3>
          <p className="ont-teko">Graphs and charts showing the engagement trends over time for individual posts.</p>
        </div>
        <div>
          {/* Comparative Analysis Tool */}
          <h3 className="text-lg ont-teko font-semibold mb-2">Comparative Analysis Tool:</h3>
          <p className="ont-teko">Allows comparison of engagement metrics between different posts.</p>
        </div>
      </div>
    </div>
  );
};

export default PostListings;
