import React from "react";

const StaticPageEditor = () => {
  return (
    <div className="p-8">
      {/* Page Management Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Static Page Editor</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* WYSIWYG Editor */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Page Management Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">WYSIWYG Editor:</h3>
            <p>Provides a 'What You See Is What You Get' interface for creating and editing static pages like terms of service, privacy policy, and about us pages.</p>
          </div>
          {/* Template Selection */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Template Selection:</h3>
            <p>Offers pre-designed templates for various types of static content to streamline the creation process.</p>
          </div>
          {/* Publishing Controls */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Publishing Controls:</h3>
            <p>Allows administrators to preview, save drafts, and publish or update static pages directly.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticPageEditor;
