// src/FinanceDashboard.js

import React, { useState, useEffect } from 'react';

const FinanceDashboard = () => {
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  // Simulated recent transactions data
  useEffect(() => {
    // Simulated recent transactions data (replace with real data fetching logic)
    const transactions = [
      { id: 1, description: 'Payment received from Client A', amount: 500 },
      { id: 2, description: 'Purchase of Equipment', amount: -200 },
      { id: 3, description: 'Monthly Subscription Fee', amount: -50 },
      { id: 4, description: 'Payment received from Client B', amount: 300 },
    ];
    setRecentTransactions(transactions);
  }, []);
                                                                                           
  // Simulated revenue trends data
  useEffect(() => {
    // Simulated revenue trends data (replace with real data fetching logic)
    const revenue = [
      { month: 'Jan', amount: 1000 },
      { month: 'Feb', amount: 1500 },
      { month: 'Mar', amount: 1200 },
      { month: 'Apr', amount: 1800 },
      { month: 'May', amount: 2000 },
      { month: 'Jun', amount: 2500 },
    ];
    setRevenueData(revenue);
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-8">Finance and Transaction</h1>

      {/* Recent Transactions Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Recent Transactions</h2>
        <ul className="divide-y divide-gray-300">
          {recentTransactions.map((transaction) => (
            <li key={transaction.id} className="py-2 flex justify-between items-center">
              <span className="text-gray-800">{transaction.description}</span>
              <span className={`text-sm font-bold ${transaction.amount >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                {transaction.amount >= 0 ? '+' : ''}${Math.abs(transaction.amount)}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {/* Revenue Trends Widget */}
      <div className="bg-pastalblue rounded-lg shadow-md p-6">
        <h2 className="text-xl Teko sans-serif font-bold mb-4">Revenue Trends</h2>
        <div className="flex items-center justify-center">
          <svg className="w-full h-32">
            {revenueData.map((dataPoint, index) => (
              <rect
                key={index}
                x={index * 40}
                y={120 - dataPoint.amount / 10}
                width="30"
                height={dataPoint.amount / 10}
                fill="#3182CE"
              />
            ))}
          </svg>
        </div>
        <div className="flex justify-between mt-4">
          {revenueData.map((dataPoint, index) => (
            <div key={index} className="text-center">
              <span className="text-sm text-gray-600">{dataPoint.month}</span>
              <span className="block font-bold">${dataPoint.amount}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinanceDashboard;
