import React from "react";

const AffiliateProgramManagement = () => {
  return (
    <div className="p-8">
      {/* Affiliate Dashboard Subpage */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Affiliate Program Management</h2>
        <div className="bg-pastalblue shadow-md rounded-lg p-6">
          {/* Affiliate Setup Tool */}
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-4">Affiliate Dashboard Subpage</h2>
            <h3 className="text-lg font-semibold mb-2">Affiliate Setup Tool:</h3>
            <p>Interface for creating and managing affiliate programs, including setting commission rates, payment terms, and program rules.</p>
          </div>
          {/* Affiliate Tracking System */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Affiliate Tracking System:</h3>
            <p>Monitors affiliate performance, tracking referrals, sales, and commission payments.</p>
          </div>
        </div>
      </div>

      {/* Affiliate Partner Management Subpage */}
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Affiliate Partner Management Subpage</h2>
        {/* Partner Onboarding Tool */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Partner Onboarding Tool:</h3>
          <p>Facilitates the registration and setup process for new affiliates, including training materials and marketing assets.</p>
        </div>
        {/* Performance Analysis Tool */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Performance Analysis Tool:</h3>
          <p>Analyses the effectiveness of each affiliate partner, highlighting top performers and identifying areas for improvement.</p>
        </div>
      </div>

      {/* Enhancements and Suggestions */}
    <div className="mt-6">
      {/* Enhancements and Suggestions */}
      <h2 className="text-3xl font-semibold mb-4 text-primaryblue">Enhancements and Suggestions</h2>
      <div className="bg-pastalblue shadow-md rounded-lg p-6">
        {/* Integrated Marketing Calendar */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Integrated Marketing Calendar:</h3>
          <p>Develop an integrated calendar within the Email Campaign Builder to plan, schedule, and track all marketing activities in one place, ensuring consistent campaign execution.</p>
        </div>
        {/* Advanced Segmentation and Personalization */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Advanced Segmentation and Personalization:</h3>
          <p>Enhance audience segmentation tools to include more granular targeting options based on user data analysis, enabling more personalized and effective email campaigns.</p>
        </div>
        {/* Comprehensive Affiliate Marketplace */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Comprehensive Affiliate Marketplace:</h3>
          <p>Create a centralized platform where potential affiliates can learn about the program, sign up, access promotional materials, and monitor their performance.</p>
        </div>
        {/* Real-Time Notification System for Affiliates */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Real-Time Notification System for Affiliates:</h3>
          <p>Implement a real-time alert system to notify administrators of significant affiliate activities or performance milestones.</p>
        </div>
        {/* ROI Analysis for Campaigns and Affiliates */}
        <div>
          <h3 className="text-lg font-semibold mb-2">ROI Analysis for Campaigns and Affiliates:</h3>
          <p>Integrate tools to calculate the return on investment (ROI) for individual marketing campaigns and affiliate partnerships, helping to direct resources towards the most profitable activities.</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AffiliateProgramManagement;
